<template>
  <div class="table">
    <a class="btn-add" href="/event/add">
      <svg class="mr-2 svg-main" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g>
          <g>
            <g>
              <path fill="#fff"
                    d="M15.88 29.539c-7.393.006-13.398-5.98-13.414-13.372-.017-7.383 6.02-13.41 13.42-13.397 7.404.014 13.401 6.006 13.397 13.387-.003 7.387-6.002 13.375-13.404 13.382zM16.804 32c.601-.081 1.207-.141 1.804-.246 3.604-.637 6.633-2.327 9.077-5.035 2.302-2.55 3.607-5.558 3.987-8.968l.076-.668v-1.857c-.081-.6-.136-1.204-.247-1.798-.89-4.747-3.418-8.381-7.538-10.89C21.284.908 18.34.179 15.209.33c-4.49.218-8.253 2.023-11.244 5.375C1.713 8.228.451 11.203.076 14.558L0 15.226v1.857c.081.6.136 1.204.247 1.798.888 4.745 3.42 8.372 7.532 10.888 2.12 1.296 4.443 2.005 6.926 2.18.08.006.16.033.24.051z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M16.926 14.98h.342c1.231 0 2.462.002 3.693 0 .42-.002.785.116 1.02.485a.985.985 0 0 1 .045 1.05c-.2.375-.526.555-.948.556-1.275.002-2.55.001-3.824.001h-.328v.29c0 1.263.003 2.527-.001 3.79-.002.653-.414 1.115-.999 1.136-.633.023-1.096-.436-1.1-1.113-.005-1.264-.001-2.527-.001-3.791v-.312h-.306c-1.292 0-2.584.004-3.877-.003a1.041 1.041 0 1 1-.701-1.808c.243-.223.538-.281.854-.281h4.03v-.309c0-1.255-.002-2.51.001-3.764.002-.57.305-.986.791-1.106.685-.168 1.298.319 1.306 1.057.01.992.003 1.983.003 2.975v1.147z"/>
            </g>
          </g>
        </g>
      </svg>
      <svg class="mr-2 svg-hover" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g>
          <g>
            <g>
              <path fill="#15b653"
                    d="M15.88 29.539c-7.393.006-13.398-5.98-13.414-13.372-.017-7.383 6.02-13.41 13.42-13.397 7.404.014 13.401 6.006 13.397 13.387-.003 7.387-6.002 13.375-13.404 13.382zM16.804 32c.601-.081 1.207-.141 1.804-.246 3.604-.637 6.633-2.327 9.077-5.035 2.302-2.55 3.607-5.558 3.987-8.968l.076-.668v-1.857c-.081-.6-.136-1.204-.247-1.798-.89-4.747-3.418-8.381-7.538-10.89C21.284.908 18.34.179 15.209.33c-4.49.218-8.253 2.023-11.244 5.375C1.713 8.228.451 11.203.076 14.558L0 15.226v1.857c.081.6.136 1.204.247 1.798.888 4.745 3.42 8.372 7.532 10.888 2.12 1.296 4.443 2.005 6.926 2.18.08.006.16.033.24.051z"/>
            </g>
            <g>
              <path fill="#15b653"
                    d="M16.926 14.98h.342c1.231 0 2.462.002 3.693 0 .42-.002.785.116 1.02.485a.985.985 0 0 1 .045 1.05c-.2.375-.526.555-.948.556-1.275.002-2.55.001-3.824.001h-.328v.29c0 1.263.003 2.527-.001 3.79-.002.653-.414 1.115-.999 1.136-.633.023-1.096-.436-1.1-1.113-.005-1.264-.001-2.527-.001-3.791v-.312h-.306c-1.292 0-2.584.004-3.877-.003a1.041 1.041 0 1 1-.701-1.808c.243-.223.538-.281.854-.281h4.03v-.309c0-1.255-.002-2.51.001-3.764.002-.57.305-.986.791-1.106.685-.168 1.298.319 1.306 1.057.01.992.003 1.983.003 2.975v1.147z"/>
            </g>
          </g>
        </g>
      </svg>
      Toevoegen
    </a>
    <router-link :to="{ name: 'Event', params: { id: event.ID } }" class="item flex justify-between items-center"
                 v-for="event in events">
      <h2>{{ event.post_title }}</h2>
      <span class="flex items-center justify-center btn-edit">
       <svg class="mr-2 svg-main" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g><g><g><path
           fill="#001948"
           d="M.282 6.542c.138-.373.226-.775.423-1.113.557-.951 1.419-1.457 2.52-1.465 2.626-.018 5.252-.008 7.878-.005.61.001 1.05.433 1.043 1.005-.007.56-.446.974-1.048.974-2.572.002-5.144.001-7.716.001-.73 0-1.118.383-1.118 1.11V20.65c0 .72.382 1.105 1.097 1.106h13.624c.707 0 1.094-.398 1.094-1.113 0-2.58-.002-5.16.001-7.74 0-.723.647-1.202 1.308-.978.416.14.669.512.671 1.006.004.888.001 1.776.001 2.665v4.935c0 1.627-.758 2.653-2.315 3.134-.013.004-.025.017-.037.026H2.6c-.055-.023-.109-.05-.165-.068-.783-.236-1.393-.702-1.773-1.424-.173-.328-.256-.703-.38-1.057v-14.6z"/></g><g><path
           fill="#001948"
           d="M24.011 2.787c-.128.792-.6 1.366-1.168 1.888-.312.286-.6.598-.878.877l-3.494-3.495c.025-.026.086-.091.149-.155.376-.377.75-.756 1.13-1.129.71-.696 1.556-.945 2.507-.653.95.29 1.52.959 1.715 1.939.006.028.026.053.04.08z"/></g><g><path
           fill="#001948"
           d="M17.351 3.18L20.84 6.67c-.038.04-.089.095-.143.15-2.506 2.506-5.01 5.015-7.525 7.515a1.175 1.175 0 0 1-.55.299c-1.079.233-2.162.44-3.244.654-.445.088-.735-.193-.648-.634.222-1.12.447-2.239.68-3.356a.75.75 0 0 1 .185-.36c2.573-2.582 5.152-5.16 7.73-7.737.01-.01.025-.018.027-.02z"/></g></g></g></svg>
        <svg class="mr-2 svg-hover" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g><g><g><path
            fill="#ffffff"
            d="M.282 6.542c.138-.373.226-.775.423-1.113.557-.951 1.419-1.457 2.52-1.465 2.626-.018 5.252-.008 7.878-.005.61.001 1.05.433 1.043 1.005-.007.56-.446.974-1.048.974-2.572.002-5.144.001-7.716.001-.73 0-1.118.383-1.118 1.11V20.65c0 .72.382 1.105 1.097 1.106h13.624c.707 0 1.094-.398 1.094-1.113 0-2.58-.002-5.16.001-7.74 0-.723.647-1.202 1.308-.978.416.14.669.512.671 1.006.004.888.001 1.776.001 2.665v4.935c0 1.627-.758 2.653-2.315 3.134-.013.004-.025.017-.037.026H2.6c-.055-.023-.109-.05-.165-.068-.783-.236-1.393-.702-1.773-1.424-.173-.328-.256-.703-.38-1.057v-14.6z"/></g><g><path
            fill="#ffffff"
            d="M24.011 2.787c-.128.792-.6 1.366-1.168 1.888-.312.286-.6.598-.878.877l-3.494-3.495c.025-.026.086-.091.149-.155.376-.377.75-.756 1.13-1.129.71-.696 1.556-.945 2.507-.653.95.29 1.52.959 1.715 1.939.006.028.026.053.04.08z"/></g><g><path
            fill="#ffffff"
            d="M17.351 3.18L20.84 6.67c-.038.04-.089.095-.143.15-2.506 2.506-5.01 5.015-7.525 7.515a1.175 1.175 0 0 1-.55.299c-1.079.233-2.162.44-3.244.654-.445.088-.735-.193-.648-.634.222-1.12.447-2.239.68-3.356a.75.75 0 0 1 .185-.36c2.573-2.582 5.152-5.16 7.73-7.737.01-.01.025-.018.027-.02z"/></g></g></g></svg>
        Wijzig</span>
    </router-link>
  </div>
</template>

<script setup>
import {inject, onMounted, computed} from 'vue'
import {useStore}                    from 'vuex';

const store = useStore();
const events = computed(() => store.state.events.events || []);

onMounted(() => {
  store.dispatch('events/getEvents')
});


</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

.table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #707070;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
}

.btn-edit {
  & .svg-hover {
    display: none;
  }
  transition: 0.5s;
  &:hover {
    color: white;
    background-color: #001948;
    & .svg-hover {
      display: block;
    }
    & .svg-main {
      display: none;
    }
  }
}
.btn-add {
  width: 150px;
  height: 48px;
  background-color: #15b653;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  border: 2px solid transparent;
  transition: 0.5s;
  & .svg-hover {
    display: none;
  }
  &:hover {
    border: 2px solid #15b653;
    color: #15b653;
    background-color: white;
    & .svg-hover {
      display: block;
    }
    & .svg-main {
      display: none;
    }
  }
}

.item {
  padding: 8px;
  border-top: 1px solid #393939;

  & h2 {
    color: #393939;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
  }

  & span {
    flex-shrink: 0;
    width: 93px;
    height: 40px;
    border: 2px solid #001948;
    border-radius: 5px;
    color: #001948;
    font-size: 18px;
    font-weight: 700;

    & svg {
      margin-top: -3px;
    }
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
