import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import store from './store'
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

const app = createApp(App);
app.use(router)
app.use(store)
app.mount('#app')

