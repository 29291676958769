<template>
  <div class="overflow-auto all-products w-full h-full">
    <div class="heading-container  flex justify-between">
      <h1 class="text-6xl text-left">Dashboard</h1>
    </div>
    <div class="flex flex-col text-left">
      <h2>Welkom bij het Capartas Tours dashboard.</h2>

      <p>Informatie voor producten en evenementen is te vinden op de specifieke pagina’s.</p>

      <h2>Producten:</h2>
      <p>Op deze pagina kun je alle producten wijzigen, verwijderen of nieuwe toevoegen.</p>

      <h2>Events:</h2>
      <p>Op deze pagina kun je alle events wijzigen, verwijderen of nieuwe toevoegen. Deze events zijn meestal gekoppeld aan producten. Zonder een product toe te wijzen aan een event kunnen er geen tickets met QR-codes worden gemaakt en kunnen er ook geen exports worden gemaakt.</p>

      <h2>Export:</h2>
      <p>Hier kun je exports draaien van events. Je creëert op basis van je keuzes een specifiek Excelbestand voor een event.</p>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
h2 {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
p {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
</style>
