<template>
  <div class="overflow-auto all-products w-full export">
    <div class="heading-container  flex justify-between">
      <h1 class="text-6xl text-left">Export Events</h1>
    </div>

    <div class="flex event-export flex-col items-start mt-5">
      <h2 class="titles">Event</h2>
      <label class="label mb-3">
        Kies een te exporteren event
        <select class="ml-5" @change="getPostsByEvent" v-model="exportSettings.event">
          <option :key="event.ID" :value="event.ID" v-for="event in events">{{ event.post_title }}</option>
        </select>
      </label>

      <h2 class="titles">Soort ticket</h2>
      <label class="label mb-3">
        Kies een te exporteren ticketsoort
        <select v-if="productsByEvent.length" class="ml-5" v-model="exportSettings.eventType">
          <option value="" selected>Alle</option>
          <option :key="product.post_id" :value="product.post_id" v-for="product in productsByEvent">
            {{ product.post_title }}
          </option>
        </select>
        <p v-else class="ml-5">There are no ticket types assigned to this event </p>
      </label>

      <h2 class="titles">Datum bereik</h2>
      <div class="wrap flex items-center range mb-3">
        <p class="label mr-5">Kies een van – tot datum of een specifieke datum</p>
        <div class="flex export-data-wrapper">
          <label class="text-2xl flex flex-col items-start ">
            Vanaf
            <Datepicker class="mr-3" cancelText="Annuleer" selectText="Kies" v-model="exportSettings.dateFrom" placeholder="Kies datum"></Datepicker>
          </label>
          <label class="text-2xl flex  flex-col items-start">
            Tot
            <Datepicker cancelText="Annuleer" selectText="Kies" v-model="exportSettings.dateTo" placeholder="Kies datum"></Datepicker>
          </label>
        </div>
      </div>

      <h2 class="titles">Gewenste gegevens</h2>
      <div class="wrap flex ">
        <p class="label mr-5 items-start">CSV  Kies wat je terug wilt zien in de sheet</p>
        <div class="flex flex-col">
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_buyer_first_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Voornaam klant
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_buyer_last_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Achternaam klant
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_buyer_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Volledige naam klant
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_buyer_email" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            E-mailadres klant
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_owner_first_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Voornaam tickethouder
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_owner_last_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Achternaam tickethouder
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_owner_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Volledige naam tickethouder
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_owner_email" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            E-mailadres tickethouder
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_payment_date" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Datum betaling
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_payment_gateway" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Betaalwijze
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_order_number" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Bestelnummer
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_order_total" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Totaal bestelling
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_order_total_once" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Totaal bestelling (1x tonen)
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_subtotal" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Ticket subtotaal
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_discount" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Ticket korting
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_tax" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Ticket btw
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_total" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Tickettotaal
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_order_status" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Status bestelling
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_id" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Ticket code
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_instance_id" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Ticket ID
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_ticket_type" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Soort ticket
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_checked_in" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Ingecheckt
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_checkins" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Aantal incheckingen
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_checkouts" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Aantal uitcheckingen
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_owner_api_key" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            API sleutel
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_order_price" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Prijs
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_company_name" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Bedrijfsnaam
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_address_1" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Adres 1
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_address_2" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Adres 2
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_city" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Plaats
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_state" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Provincie
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_postcode" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Postcode
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_country" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Land
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_phone" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Telefoonnummer
          </label>
          <label class="label checkbox">
            <input true-value="1" false-value="0" v-model="exportSettings.fields.col_woo_coupon_code" class="mr-3"
                   type="checkbox">
            <span class="checkmark"></span>
            Kortingscode
          </label>
        </div>
      </div>

      <h2 class="titles">Status bestelling</h2>
      <div class="wrap flex items-center mb-3">
        <p class="label mr-5">Kies bepaalde status of elke status van de bestelling
        </p>
        <label class="text-2xl flex text-left mt-3 items-center">
          <select class="h-10 ml-5" v-model="exportSettings.statusOrder">
            <option :key="statusItem" v-for="statusItem in orderStatus" :value="statusItem.id">{{
                statusItem.label
              }}
            </option>
          </select>
        </label>
      </div>

      <h2 class="titles">Naam document</h2>
      <div class="wrap flex items-center mb-3">
        <p class="label mr-5">Naam van het exceldocument</p>
        <label class="text-2xl flex text-left mt-3 items-center">
          <input type="text" v-model="exportSettings.docTitle">
        </label>
      </div>

      <button @click.prevent="sendData" type="button" class="export-btn">
        {{ !loading ? 'Exporteer data' : 'Exporteer data...' }}
      </button>

      <p class="mt-5 text-3xl text-bold text-red-500" v-if="errorExport">{{ errorExport }}</p>

      <xlsx-workbook class="hidden">
        <xlsx-sheet
            :collection="exportData"
            v-for="(sheet, index) in exportData"
            :key="index"
            :sheet-name="exportSettings.docTitle ? exportSettings.docTitle : 'Exporteer data'"
        />
        <xlsx-download filename="testFile.xlsx">
          <button ref="exportDataProcessing">Download</button>
        </xlsx-download>
      </xlsx-workbook>

    </div>
  </div>

</template>

<script setup>
import {computed, onMounted, ref}              from "vue";
import {useStore}                              from 'vuex';
import Datepicker                              from '@vuepic/vue-datepicker';
import {format}                                from "date-fns";
import axios                                   from "axios";
import {Config}                                from "@/config";
import JsonExcel                               from "vue-json-excel3";
import {XlsxWorkbook, XlsxSheet, XlsxDownload} from 'vue3-xlsx'
import {createToaster}                         from "@meforma/vue-toaster";

const Toaster = createToaster({position: 'bottom-right', duration: 3000, useDefaultCss: true});

const store = useStore();
const events = computed(() => store.state.events.events || []);
const loading = ref(false);
const exportSettings = ref({
  event      : '',
  dateFrom   : '',
  dateTo     : '',
  eventType  : '',
  fields     : {
    col_buyer_first_name  : 1,
    col_buyer_last_name   : 1,
    col_buyer_name        : 1,
    col_buyer_email       : 1,
    col_owner_first_name  : 1,
    col_owner_last_name   : 1,
    col_owner_name        : 1,
    col_owner_email       : 1,
    col_payment_date      : 1,
    col_payment_gateway   : 1,
    col_order_number      : 1,
    col_order_total       : 1,
    col_order_total_once  : 1,
    col_ticket_subtotal   : 1,
    col_ticket_discount   : 1,
    col_ticket_tax        : 1,
    col_ticket_total      : 1,
    col_order_status      : 1,
    col_ticket_id         : 1,
    col_ticket_instance_id: 1,
    col_ticket_type       : 1,
    col_checked_in        : 1,
    col_checkins          : 1,
    col_checkouts         : 1,
    col_owner_api_key     : 1,
    col_order_price       : 1,
    col_woo_company_name  : 1,
    col_woo_address_1     : 1,
    col_woo_address_2     : 1,
    col_woo_city          : 1,
    col_woo_state         : 1,
    col_woo_postcode      : 1,
    col_woo_country       : 1,
    col_woo_phone         : 1,
    col_woo_coupon_code   : 1,
  },
  statusOrder: 'any',
  docTitle   : ''
});
const productsByEvent = ref('');
const exportData = ref();
const exportDataProcessing = ref();
const interval = ref();
const errorExport = ref('');

const orderStatus = [
  {
    id: 'any', label: 'Any'
  },
  {
    id: 'wc-completed', label: 'Completed'
  },
  {
    id: 'wc-processing', label: 'Processing'
  },
  {
    id: 'wc-on-hold', label: 'On Hold'
  },
  {
    id: 'wc-cancelled', label: 'Cancelled'
  },
  {
    id: 'wc-pending', label: 'Pending Payment'
  },
  {
    id: 'wc-refunded', label: 'Refunded'
  },
]
const API_URL_POST = Config.APP_WP_URL + '/wp-json/api/headless/exportSettings';
const API_URL_PRODUCTS_BY_EVENT = Config.APP_WP_URL + '/wp-json/api/headless/productsByEvent';

onMounted(async () => {
  store.dispatch('events/getEvents')
});

async function sendData()
{
  loading.value = true;
  exportSettings.value.eventType = exportSettings.value.eventType === '' && productsByEvent.value.length ? productsByEvent.value.map(el => el.post_id) : exportSettings.value.eventType
  let data = JSON.parse(JSON.stringify(exportSettings.value))
  const formData = new FormData();

  data.dateFrom = data.dateFrom ? format(new Date(data.dateFrom), 'yyyy-MM-dd HH:mm') : ''
  data.dateTo = data.dateTo ? format(new Date(data.dateTo), 'yyyy-MM-dd HH:mm') : ''

  formData.append('data', JSON.stringify(data));

  var response = await axios.post(API_URL_POST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  response = JSON.parse(response.data);

  if (!response.value) {
    loading.value = false;
    Toaster.error('No data for those parameters', {type: 'error'});
    return;
  } else {
    loading.value = false;
    Toaster.success('File is downloading...', {});
  }

  exportData.value = response.value;
  interval.value = setInterval(function () {
    if (exportData.value) {
      exportDataProcessing.value.click();
      clearInterval(interval.value);
    }
  }, 500);

}

async function getPostsByEvent()
{
  let data = JSON.parse(JSON.stringify(exportSettings.value.event))
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  var response = await axios.post(API_URL_PRODUCTS_BY_EVENT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  productsByEvent.value = response.data;
}


</script>

<style lang="scss">
.export {
  .event-export {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: 1px solid #707070;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 20px;
    flex-direction: column;
    margin-bottom: 150px;
  }

  .export-data-wrapper {
    @media (max-width: 550px) {
      flex-direction: column;
    }
  }

  .titles {
    color: #393939;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }

  .export-btn {
    width: 155px;
    height: 42px;
    color: #ffffff;
    background-color: #15b653;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: 0.8s;

    &:hover {
      border: 2px solid #15b653;
      background-color: white;
      color: #15b653;
    }

    @media (max-width: 1020px) {
      margin-top: 20px !important;
    }

    &.loading {
      position: relative;
      border: 2px solid #15b653;
      background-color: white;
      color: #15b653;

      &:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-image: url("../assets/loader.gif");
        background-size: 30px 30px;
      }
    }
  }

  .dp__main {
    @media (max-width: 1020px) {
      margin: 5px 0!important;
    }
  }

  .wrap {
    @media (max-width: 1020px) {
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: flex-start;
      & br {
        display: none;
      }
    }

  }

  .label {
    display: flex;
    align-items: center;
    color: #393939;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 5px;
    @media (max-width: 1020px) {
      flex-direction: column;
      align-items: flex-start;
      & p {
        margin-left: 0;
        margin-top: 5px;
      }
      & label {
        margin-top: 5px;
      }
    }
  }

  .range input {
    padding-left: 35px !important;
  }

  select, input {
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: #ffffff;
    height: 30px;

    color: #393939 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: left;
    width: 100%;
    max-width: 300px !important;

    padding-left: 5px !important;

    @media (max-width: 1020px) {
      margin-left: 0 !important;
    }
  }

  .checkbox {
    position: relative;
    padding-left: 25px !important;
    margin-bottom: 20px;
    cursor: pointer;

    & input {
      display: none;

      &:checked ~ .checkmark {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translateY(-50%);
        background-color: #707070;
      }
    }

    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border: 1px solid #707070;
    }
  }
}

</style>
