<template>
  <div class="evenement mt-12">
    <div class="flex justify-between items-end">
      <h2 class="label-title">Evenement / Rit informatie</h2>
      <div class="tabs flex">
        <button class="flex btn-nav mr-2" :class="{'active':evenement === 1}"
                @click="evenement = 1" type="button">Algemene Informatie
        </button>
        <button class="flex btn-nav mr-2" :class="{'active':evenement === 2}"
                @click="evenement = 2" type="button">Opstapplaatsen
        </button>
        <button class="flex btn-nav mr-2" :class="{'active':evenement === 3}"
                @click="evenement = 3" type="button">Extra informatie
        </button>
        <button class="flex btn-nav" :class="{'active':evenement === 4}"
                @click="evenement = 4" type="button">Opsomming
        </button>
      </div>
    </div>
    <div class="flex mt-4">
      <div class="content w-full">
        <div class="flex w-full" v-if="evenement === 1">
          <Editor  @update:content="emit('update:algemene_informatie', $event)" :height="500" :content="algemene_informatie"></Editor>
        </div>
        <div v-if="evenement === 2">
          <Editor  @update:content="emit('update:opstapplaatsen', $event)" :height="500" :content="opstapplaatsen"></Editor>
        </div>
        <div v-if="evenement === 3">
          <Editor  @update:content="emit('update:extra_informatie', $event)" :height="500" :content="extra_informatie"></Editor>
        </div>
        <div class="flex flex-col" v-if="evenement === 4">
          <div class="flex items-center mb-4 h-10" v-for="(item,index) in opsomming" :key="item.id">
            <label class="checkbox">
              <input @change="opsomming.splice(index, 1);" class="mr-5" type="checkbox" checked>
              <span class="checkmark"></span>
            </label>
            <input class="w-1/6 pl-4 text-2xl" v-model="item.value">
          </div>
          <button
              @click="addToOpsomming"
              class="btn-add" type="button">Voeg toe
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import Editor from '@/components/FormComponents/Editor';

const evenement = ref(1);

const props = defineProps({
  algemene_informatie: {
    type    : String,
  },
  opstapplaatsen: {
    type    : String,
  },
  extra_informatie: {
    type    : String,
  },
  opsomming: {
    type    : Array,
  },
});

const emit = defineEmits(['addTo', 'update:algemene_informatie','update:opstapplaatsen','update:extra_informatie' ])

function addToOpsomming() {
  emit('addTo')
}
</script>

<style scoped lang="scss">
.tabs {
  @media (max-width: 639px) {
    flex-direction: column;
    width: 100%;
    & button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.evenement {
  margin-bottom: 41px;
  @media (max-width: 1647px) {
    & > div {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
.tox-statusbar {
  @media (max-width: 500px) {
    display: none;
  }
}
.label-title {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
}
.btn-add {
  width: 120px;
  height: 22px;
  border-radius: 5px;
  border: 1px solid #001948;
  justify-content: center;
  align-items: center;
  color: #001948;
  font-size: 14px;
  font-weight: 700;
  transition: 0.5s;
  &:hover {
    background-color: #001948;
    color: white;
  }
}
.btn-nav {
  width: 183px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #001948;
  justify-content: center;
  align-items: center;
  color: #001948;
  font-size: 16px;
  font-weight: 700;
  transition: 0.5s;
  @media (max-width: 1300px) {
    width: 125px;
    font-size: 12px;
  }
  &.active {
    background-color: #001948;
    color: white;
  }
  &:hover {
    background-color: #001948;
    color: white;
  }
}

input {
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  height: 30px;

  color: #393939!important;
  font-size: 14px!important;
  font-weight: 500!important;
  text-align: left;
  width: 100%;
  max-width: 300px!important;

  padding-left: 5px!important;
}

.checkbox {
  position: relative;
  padding-left: 25px!important;
  cursor: pointer;
  & input {
    display: none;
    &:checked ~ .checkmark{
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left:3px;
      transform: translateY(-50%);
      background-color: #707070;
    }
  }
  &:before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border: 1px solid #707070;
  }
}
</style>
