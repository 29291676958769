<template>
  <div class="overflow-auto all-products w-full p-10 product">
    <template v-if="eventItem">
      <EventForm @formSubmit="sendData" :eventItem="eventItem" :loading="loading"></EventForm>
    </template>
  </div>
</template>

<script setup>
import Heading                    from "@/components/Heading";
import EventForm                  from "@/components/EventForm.vue";
import axios                      from 'axios';
import {useRoute}                 from 'vue-router'
import {useStore}                 from 'vuex';
import {onMounted, computed, ref} from 'vue'
import until                      from '@/until/index.js';
import {Config}                   from "@/config";
import {format}                   from "date-fns";
import {createToaster} from "@meforma/vue-toaster";

const Toaster = createToaster({ position:'bottom-right', duration:3000,useDefaultCss: true});
const route = useRoute();
const store = useStore();
const eventId = route.params.id;
const eventItem = ref();
const loading = ref(false);

const API_URL_POST = Config.APP_WP_URL + '/wp-json/api/headless/event/save';

onMounted(async () => {
  eventItem.value = await store.dispatch('events/getEvent', eventId)
});


function sendData(data)
{
  loading.value = true;
  data.post_meta.event_date_time = data.post_meta.event_date_time ? format(new Date(data.post_meta.event_date_time), 'yyyy-MM-dd HH:mm') : ''
  data.post_meta.event_end_date_time = data.post_meta.event_end_date_time ? format(new Date(data.post_meta.event_end_date_time), 'yyyy-MM-dd HH:mm') : ''

  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    const response = await axios.post(API_URL_POST, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    resolve(response.data)

    if (response.status === 200) {
      loading.value = false;
      Toaster.success('Opgeslagen', {});
    } else {
      loading.value = false;
      Toaster.error('Opslaan mislukt', {});
    }
  })
}



</script>
<style lang="scss">
.product {
  background-color: white;
  padding: 40px 56px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1380px) {
    padding: 30px;
  }
  @media (max-width: 768px) {
    padding: 15px;
  }
  & h1 {
    color: #1f1f1f;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
    margin-left: 24px;
    margin-bottom: 28px;
  }
}
</style>
