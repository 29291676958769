<template>
  <editor
      v-model="modelValue"
      :api-key="apiKey"
      :init="{width: width, height: height, plugins: plugins, language: 'nl',
         toolbar:toolbar}"/>
</template>

<script setup>
import Editor          from '@tinymce/tinymce-vue';
import {computed} from 'vue'
const emit = defineEmits(['update:content']);
const modelValue = computed({
      get()
      {
        return props.content
      },
      set($event)
      {
        emit('update:content', $event)
      }
    })
const props = defineProps({
  height : {
    type: Number,
  },
  width  : {
    type   : String,
    default: '100%'
  },
  plugins: {
    type   : Array,
    default: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ]
  },
  toolbar: {
    type   : String,
    default: 'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help '
  },
  apiKey : {
    type   : String,
    default: 'o82emrv7ui2sth6wxbh57j6ty2y0z67h49grzwrkahd2zcto',
  },
  content: {
    type: String,
  },
});

</script>

<style scoped>

</style>
