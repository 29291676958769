import axios from 'axios';
import {Config} from '../config/index'

const API_URL_LOGIN = Config.APP_WP_URL + '/wp-json/jwt-auth/v1/token';

class UserService{
    login({username, password}) {
        return  axios.post(API_URL_LOGIN,{username, password} );
    }
    checkLogin(token) {
        return axios.post(API_URL_LOGIN + '/validate', {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
    );
    }
}

export default new UserService()

