<template>
  <div class="container-login">
    <div class="wrapper">
      <label >
        Gebruikersnaam
        <input v-model="user.username" type="text" name="username">
      </label>
      <label>
        Wachtwoord
        <input v-model="user.password" type="password" name="password">
      </label>
      <button @click="login" :class="{'loading': loading}" type="button">  {{!loading ? 'Inloggen' : 'Inloggen...'}}</button>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive} from 'vue';
import {useStore}                 from 'vuex';
import {useRouter}                 from 'vue-router'
import {createToaster} from "@meforma/vue-toaster";


const Toaster = createToaster({ position:'bottom-right', duration:2000,useDefaultCss: true});
const store = useStore();
const router = useRouter();
const loading = ref(false);
const user = reactive({username:'', password:''})

async function login() {
  loading.value = true;
  const userDataResponse = await store.dispatch('user/login',user);
  if (userDataResponse.status === 200) {
    loading.value = false;
    await router.push({name:'Dashboard'})
  } else {
    loading.value = false;
    Toaster.error('Gebruikersnaam en/of wachtwoord niet correct.', {});
  }
}

</script>

<style lang="scss">
.wrapper {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border: 1px solid #707070;
  & label {
    text-align: left;
    width: 100%;
    color: #393939;
    font-size: 16px;
    font-weight: 600;
  }
  & input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #707070;
    color: #393939 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: left;
    max-width: 300px !important;
    padding-left: 5px !important;
    background-color: transparent;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  & button {
    width: 155px;
    height: 42px;
    background-color: #15b653;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;

    border: 2px solid transparent;
    transition: border 0.8s, background-color 0.8s, color 0.8s;

    &:hover {
      border: 2px solid #15b653;
      background-color: white;
      color: #15b653;
    }

    &.loading {
      pointer-events: none;
      position: relative;
      border: 2px solid #15b653;
      background-color: white;
      color: #15b653;
      padding-left: 19px;
      &:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-image: url("../assets/loader.gif");
        background-size: 30px 30px;
      }
    }
  }
}
.container-login {
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(100% - 134px);
  align-items: center;
}
.c-toast{
  width: 400px!important;
  height: fit-content!important;
}
</style>
