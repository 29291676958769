import axios from 'axios';
import {Config} from '../config/index'

const API_URL_GET = Config.APP_WP_URL + '/wp-json/api/headless/events';

class EventsService{
    getAllEvents() {
        return  axios.get(API_URL_GET + '/getall');
    }
    getEvent(id) {
        return  axios.get(API_URL_GET + '/get/' + id);
    }
    // editProduct(id) {
    //     return  axios.get(API_URL_GET + '/edit/' + id);
    // }
    // addProduct() {
    //     return  axios.get(API_URL_GET + '/add');
    // }
}

export default new EventsService()

