import UserService from '@/services/user.service'

export const user = ({
    namespaced: true,
    state: {
        user : {},
        isLoginIn: !!localStorage.getItem('logged_in'),
        checked: false,
    },
    getters: {
    },
    mutations: {
        SET_USER (state, data) {
            state.user = data;
            state.isLoginIn = true;
        },
        REMOVE_USER (state) {
            state.user = {};
            state.isLoginIn = false;
        },
        CHECK_USER (state, status) {
            state.checked = status;
        },
    },
    actions: {
        async login({commit}, user) {
            // return UserService.login(user).then((response) => {
            //     console.log(21321, response)
            //     commit('SET_USER', response.data)
            //     localStorage.setItem('token', response.data.token)
            //     localStorage.setItem('user_display_name', response.data.user_display_name)
            //     localStorage.setItem('logged_in', 'true')
            //     return Promise.resolve(response)
            // });

            try {
                const response = await UserService.login(user)
                commit('SET_USER', response.data)
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('user_display_name', response.data.user_display_name)
                localStorage.setItem('logged_in', 'true');
                commit('CHECK_USER',true)
                return response
            }catch (e){
                return false
            }
        },
        async checkLogin({commit}) {
            const token = localStorage.getItem('token');
            if (!token) {
                localStorage.removeItem('user_display_name')
                localStorage.removeItem('logged_in')
                commit('REMOVE_USER');
            }
            try {
                await UserService.checkLogin(token)
                commit('SET_USER', { user_display_name : localStorage.getItem('user_display_name')})
                commit('CHECK_USER',true)
                return true
            }catch (e){
                localStorage.removeItem('user_display_name')
                localStorage.removeItem('logged_in')
                localStorage.removeItem('token')
                commit('REMOVE_USER');
                commit('CHECK_USER',false)
                return false
            }
        },
        logout({commit}) {
            localStorage.removeItem('user_display_name')
            localStorage.removeItem('logged_in')
            localStorage.removeItem('token')
            commit('REMOVE_USER');
        },

    },
    modules: {
    }
})
