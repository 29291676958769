<template>
  <form @submit.prevent="formSubmit" class="flex" id="product-submit">

    <div class="left w-full mr-7">

      <Main @update:content="props.product.post_content = $event" @update:title="props.product.post_title = $event"
            :content="product.post_content" :title="product?.post_title" :error="v?.post_title.$error"></Main>

      <Evenement
          @addTo="addTo"
          @update:algemene_informatie="product.post_meta.algemene_informatie = $event"
          @update:opstapplaatsen="product.post_meta.opstapplaatsen = $event"
          @update:extra_informatie="product.post_meta.extra_informatie = $event"
          :algemene_informatie="product.post_meta.algemene_informatie"
          :opstapplaatsen="product.post_meta.opstapplaatsen"
          :extra_informatie="product.post_meta.extra_informatie"
          :opsomming="product.post_meta.opsomming"
      >
      </Evenement>

      <Excerpt :post_excerpt="product.post_excerpt"
               @update:post_excerpt="props.product.post_excerpt = $event"></Excerpt>

      <h2 class="label-title">Bronvermelding</h2>
      <label class="text-2xl flex mb-8 mt-2 flex-col bronvermelding">
        <input style="max-width: 100%!important;" class="w-full" type="text" v-model="product.post_meta.bronvermelding">
      </label>

      <div class="product-settings">

        <div class="flex">
          <h2 class="label-title">Productgegevens -</h2>
          <select class="h-10 ml-5" v-model="product.type">
            <option value="simple">Simpel product</option>
            <option value="variable">Variabel product</option>
          </select>
        </div>

        <div class="flex mt-4 add-info">

          <div class="nav w-1/6 flex flex-col">
            <button class="flex btn-nav mb-4" :class="{'active':productSetting === 1}"
                    @click.prevent="productSetting = 1" type="button">Algemeen
            </button>
            <button class="flex btn-nav mb-4" :class="{'active':productSetting === 2}"
                    @click.prevent="productSetting = 2" type="button">Vooraad
            </button>
            <button class="flex btn-nav mb-4" :class="{'active':productSetting === 3}"
                    @click.prevent="productSetting = 3" type="button">Eigenschappen
            </button>
            <button v-if="product.type !== 'simple'" class="flex btn-nav mb-4" :class="{'active':productSetting === 4}"
                    @click.prevent="productSetting = 4" type="button">Variaties
            </button>
          </div>

          <div class="flex wrap flex-col w-5/6">

            <div class="flex flex-col" v-if="productSetting === 1">

              <div v-if="product.type === 'simple'">
                <label class="text-2xl flex mb-5 items-center">
                  Reguliere prijs (€)
                  <input class="ml-3" type="text" v-model="product.post_meta._regular_price">
                </label>
                <label class="text-2xl flex mb-5 items-center">
                  Actieprijs (€)
                  <input class="ml-3" type="text" v-model="product.post_meta._sale_price">
                </label>
              </div>

              <label class="text-2xl flex mb-5 checkbox">
                Ticket mogelijk maken
                <input true-value="yes" false-value="no" class="ml-3" type="checkbox"
                       v-model="product.post_meta._tc_is_ticket">
                <span class="checkmark"></span>
              </label>

              <div class="flex flex-col" v-if="product.post_meta._tc_is_ticket === 'yes'">
                <label class="text-2xl flex mb-5 items-center label-col-mobile">
                  Event
                  <select class="h-10 ml-5" v-model="product.post_meta._event_name">
                    <option :key="event.ID" :value="event.ID" v-for="event in events">{{ event.post_title }}</option>
                  </select>
                </label>
                <label class="text-2xl flex mb-5 label-col-mobile">
                  Check-ins per ticket
                  <input type="number" class="ml-3" v-model="product.post_meta._available_checkins_per_ticket">
                </label>
                <label class="text-2xl flex mb-5 checkbox">
                  Uitchecken toestaan voor ticket
                  <input true-value="yes" false-value="no" class="ml-3" type="checkbox"
                         v-model="product.post_meta._allow_ticket_checkout">
                  <span class="checkmark"></span>
                </label>
                <div class="text-2xl flex mb-5 label-col-mobile">
                  Beschikbare datums ticketverkoop
                  <div class="ml-2">
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" id="open_ended" v-model="product.post_meta._ticket_availability"
                             value="open_ended">
                      <span class="checkmark"></span>
                      <label class="text-2xl" for="open_ended">Open einde</label>
                    </label>
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" id="range" v-model="product.post_meta._ticket_availability"
                             value="range">
                      <span class="checkmark"></span>
                      <label class="text-2xl" for="range">Gedurende geselecteerde periode</label>
                    </label>
                  </div>
                </div>
                <div v-if="product.post_meta._ticket_availability === 'range'">
                  <label class="text-2xl flex mb-5 label-col-mobile items-center">
                    Verkoop toestaan vanaf <br>
                    <Datepicker locale="de"  cancelText="Annuleer" selectText="Kies" class="ml-2" v-model="product.post_meta._ticket_availability_from_date"
                                placeholder="Kies datum"></Datepicker>
                  </label>

                  <label class="text-2xl flex mb-5 label-col-mobile items-center">
                    Verkoop toestaan tot
                    <Datepicker locale="de" cancelText="Annuleer" selectText="Kies" class="ml-2" v-model="product.post_meta._ticket_availability_to_date"
                                placeholder="Kies datum"></Datepicker>
                  </label>
                </div>

                <div class="text-2xl flex mb-5 label-col-mobile">
                  Available dates / times for check-in
                  <div class="ml-2">
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" v-model="product.post_meta._ticket_checkin_availability"
                             value="open_ended">
                      <span class="checkmark"></span>
                      <label class="text-2xl" for="open_ended">Open einde</label>
                    </label>
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" v-model="product.post_meta._ticket_checkin_availability"
                             value="range"><span class="checkmark"></span>
                      <label class="text-2xl" for="range">Gedurende geselecteerde periode</label>
                    </label>
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" v-model="product.post_meta._ticket_checkin_availability"
                             value="time_after_order"><span class="checkmark"></span>
                      <label class="text-2xl" for="open_ended">Within the following time after order is placed</label>
                    </label>
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" v-model="product.post_meta._ticket_checkin_availability"
                             value="time_after_first_checkin"><span class="checkmark"></span>
                      <label class="text-2xl" for="range">Within the following time after first check-in</label>
                    </label>
                    <label class="flex mb-2 checkbox">
                      <input class="mr-3" type="radio" v-model="product.post_meta._ticket_checkin_availability"
                             value="upon_event_starts"><span class="checkmark"></span>
                      <label class="text-2xl" for="range">When the event starts</label>
                    </label>
                  </div>
                </div>
                <div v-if="product.post_meta._ticket_checkin_availability === 'range'">
                  <label class="text-2xl flex mb-5 label-col-mobile items-center">
                    Check-in allowed from
                    <Datepicker locale="de" cancelText="Annuleer" selectText="Kies" class="ml-2" v-model="product.post_meta._ticket_checkin_availability_from_date"
                                placeholder="Kies datum"></Datepicker>
                  </label>

                  <label class="text-2xl flex mb-5 label-col-mobile items-center">
                    Check-in allowed until
                    <Datepicker locale="de" cancelText="Annuleer" selectText="Kies" class="ml-2" v-model="product.post_meta._ticket_checkin_availability_to_date"
                                placeholder="Kies datum"></Datepicker>
                  </label>
                </div>
                <div v-if="product.post_meta._ticket_checkin_availability === 'time_after_order'">
                  <label class="text-2xl flex mb-5 items-center">
                    Days
                    <input class="ml-3" type="number" v-model="product.post_meta._time_after_order_days">
                  </label>
                  <label class="text-2xl flex mb-5 items-center">
                    Hours
                    <input class="ml-3" type="number" v-model="product.post_meta._time_after_order_hours">
                  </label>
                  <label class="text-2xl flex mb-5 items-center">
                    Minutes
                    <input class="ml-3" type="number" v-model="product.post_meta._time_after_order_minutes">
                  </label>
                </div>
                <div v-if="product.post_meta._ticket_checkin_availability === 'time_after_first_checkin'">
                  <label class="text-2xl flex mb-5 items-center">
                    Days
                    <input class="ml-3" type="number" v-model="product.post_meta._time_after_first_checkin_days">
                  </label>
                  <label class="text-2xl flex mb-5 items-center">
                    Hours
                    <input class="ml-3" type="number" v-model="product.post_meta._time_after_first_checkin_hours">
                  </label>
                  <label class="text-2xl flex mb-5 items-center" >
                    Minutes
                    <input class="ml-3" type="number" v-model="product.post_meta._time_after_first_checkin_minutes">
                  </label>
                </div>
              </div>

            </div>

            <div v-if="productSetting === 2">

              <label class="text-2xl flex mb-5 items-center label-col-mobile">
                Artikelnummer
                <input type="text" class="ml-3" v-model="product.post_meta._sku">
              </label>
              <label class="text-2xl flex mb-5 items-center label-col-mobile">
                GTIN
                <input type="text" class="ml-3" v-model="product.post_meta._rank_math_gtin_code">
              </label>
              <label class="text-2xl flex mb-5 checkbox">
                Voorraad beheren?
                <input true-value="yes" false-value="no" class="ml-3" type="checkbox"
                       v-model="product.post_meta._manage_stock"><span class="checkmark"></span>
              </label>
              <label
                  v-if="product.type === 'simple' && (product.post_meta._manage_stock === 'no' || !product.post_meta._manage_stock)"
                  class="text-2xl flex mb-5 items-center label-col-mobile">
                Voorraadstatus
                <select v-model="product.post_meta._stock_status" class="h-10 ml-5">
                  <option :key="stockItem" v-for="stockItem in stockSelectData" :value="stockItem.id">{{
                      stockItem.label
                    }}
                  </option>
                </select>
              </label>

              <div v-if="product.post_meta._manage_stock === 'yes'">
                <label class="text-2xl flex mb-5 items-center label-col-mobile">
                  Voorraadhoeveelheid
                  <input type="number" class="ml-3" v-model="product.post_meta._stock">
                </label>
                <label class="text-2xl flex mb-5 items-center label-col-mobile">
                  Nabestellingen toestaan?
                  <select v-model="product.post_meta._backorders" class="h-10 ml-5">
                    <option :key="stockBack" v-for="stockBack in stockBackorders" :value="stockBack.id">{{
                        stockBack.label
                      }}
                    </option>
                  </select>
                </label>
                <label class="text-2xl flex mb-5 items-center label-col-mobile">
                  Lage voorraad drempelwaarde
                  <input type="number" class="ml-3" v-model="product.post_meta._low_stock_amount">
                </label>
              </div>

              <div class="flex items-center label-col-mobile">
                <h2 class="text-2xl text-left mb-7 mr-3 mt-2">Individueel verkocht</h2>
                <label class="text-2xl flex mb-5 checkbox">
                  <input true-value="yes" false-value="no" class="mr-3" type="checkbox"
                         v-model="product.post_meta._sold_individually"><span class="checkmark"></span>
                  Activeer dit als dit artikel maar één keer per bestelling gekocht mag worden
                </label>
              </div>

            </div>

            <div v-if="productSetting === 3">
              <div class="flex items-center mb-5 label-col-mobile">
                <label class="text-2xl mr-3 flex items-center">
                  <select class="h-14" v-model="selectedAttribute">
                    <option value="custom">Custom producteingenschap</option>
                    <option :value="attribute" v-for="attribute in attributes">{{ attribute.attribute_label }}</option>
                  </select>
                </label>
                <button @click.prevent="addAttrToProductAttributes"
                        class="border h-14 border-solid border-black p-2 text-2xl btn-add" type="button">Voeg toe
                </button>
              </div>
              <div class="flex items-center wrap-item mb-4" v-for="(productAttr,key) in product.post_meta._product_attributes"
                   :key="key">
                <div class="mr-5">
                  <label v-if="productAttr.is_taxonomy === 0" class="text-2xl flex flex-col mb-5 text-left">
                    Naam:
                    <input class="" v-model="productAttr.name" type="text">
                  </label>
                  <label v-else class="text-2xl flex flex-col mb-5 text-left">
                    <span class="capitalize">{{ productAttr.name.replace('pa_', '') }}</span>
                  </label>
                  <label class="text-2xl flex mb-5 checkbox">
                    <input true-value="1" false-value="0" v-model="productAttr.is_visible" class="mr-3" type="checkbox">
                    <span class="checkmark"></span>
                    Toon op de productpagina
                  </label>
                  <label v-if="product.type !== 'simple'" class="text-2xl flex mb-5 checkbox">
                    <input true-value="1" false-value="0" class="mr-3" v-model="productAttr.is_variation"
                           type="checkbox">
                    <span class="checkmark"></span>
                    Gebruik voor variaties
                  </label>
                </div>
                <label class="text-2xl flex flex-col mb-5 flex-grow text-left label-col-mobile">
                  Waarde(n):
                  <textarea v-if="productAttr.is_taxonomy === 0" class="h-24" v-model="productAttr.value"></textarea>
                  <Select2 class="w-full relative" @update:modelValue="setAttrsForVariations($event, productAttr.name)"
                           v-else v-model="product.attribute_terms[productAttr.name]"
                           :settings="{width:'100%',multiple:true, height:'100px'}"
                           :options="termsInAttrs[productAttr.name]"/>
                </label>
                <button type="button" class="btn-add ml-4 btn-attr-remove"
                        @click.prevent="removeAttrFromProduct(key)">Verwijderen
                </button>
              </div>

            </div>

            <div class="flex flex-col items-start" v-if="productSetting === 4">

              <button @click.prevent="addVariationToProduct" class="border btn-add border-solid border-black p-2 text-2xl mb-4">
                Add variation
              </button>

              <div v-for="(variation, key) in product.variations_by_id"
                   class="wrap-item flex flex-col w-full mb-4">
                <div class="flex mb-6 variations-attrs items-center">
                  <span>{{ '#' + key }}</span>
                  <div class="ml-2" v-for="(productAttr,key) in product.post_meta._product_attributes" :key="key">
                    <Select2
                        v-model="variation['attribute_' + (productAttr.is_taxonomy ? productAttr.name : stringToSlug(productAttr.name))]"
                        v-if="!!Number(productAttr.is_variation)" class="w-full"
                        :settings="{width:'150px', height:'80px'}" :placeholder="'Iedere ' + productAttr.name"
                        :options="productAttr.is_taxonomy ? attrsForVariations[productAttr.name] : productAttr.value.split('|').map(function (el) {return el.trim()})"/>
                  </div>
                </div>
                <label class="text-2xl flex flex-col mb-6 text-left w-2/5 variation-col">
                  Artikelnummer
                  <input v-model="variation._sku" type="text">
                </label>
                <div class="flex mb-6 variation-col">
                  <label class="text-2xl flex mr-10 checkbox">
                    <input v-model="variation.post_status" true-value="publish" false-value="private" class="mr-3"
                           type="checkbox">
                    <span class="checkmark"></span>
                    Geactiveerd
                  </label>
                  <label class="text-2xl flex mr-10 checkbox">
                    <input v-model="variation._manage_stock" true-value="yes" false-value="no" class="mr-3"
                           type="checkbox">
                    <span class="checkmark"></span>
                    Voorraad beheren?
                  </label>
                </div>
                <div class="flex mb-6 variation-col">
                  <label class="text-2xl flex flex-col mr-10 text-left w-3/6">
                    Reguliere prijs (€)
                    <input v-model="variation._regular_price" placeholder="Variatieprijs (vereist)" type="text">
                  </label>
                  <label class="text-2xl flex flex-col text-left w-3/6">
                    Actieprijs (€)
                    <input v-model="variation._sale_price" type="text">
                  </label>
                </div>
                <div class="mb-5 variation-col" v-if="variation._manage_stock === 'yes'">
                  <div class="mb-5 flex variation-col">
                    <label class="text-2xl w-3/6 mr-10 flex flex-col text-left">
                      Voorraadhoeveelheid
                      <input type="number" v-model="variation._stock">
                    </label>
                    <label class="text-2xl flex w-3/6 flex-col text-left">
                      Nabestellingen toestaan?
                      <select v-model="variation._backorders">
                        <option :key="stockBack" v-for="stockBack in stockBackorders" :value="stockBack.id">{{
                            stockBack.label
                          }}
                        </option>
                      </select>
                    </label>
                  </div>

                  <label class="text-2xl flex flex-col w-full text-left">
                    Lage voorraad drempelwaarde
                    <input type="number" v-model="variation._low_stock_amount">
                  </label>
                </div>
                <label v-else class="text-2xl flex flex-col mb-6 items-start">
                  Voorraadstatus
                  <select v-model="variation._stock_status" class="mt-2 w-full">
                    <option :key="stockItem" v-for="stockItem in stockSelectData" :value="stockItem.id">{{
                        stockItem.label
                      }}
                    </option>
                  </select>
                </label>
                <label class="text-2xl flex flex-col mb-6 text-left w-full">
                  Beschrijving
                  <textarea v-model="variation._variation_description"></textarea>
                </label>
                <label class="text-2xl flex flex-col mb-6 text-left w-full">
                  GTIN
                  <input v-model="variation._rank_math_gtin_code" type="text">
                </label>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

    <div class="right h-fit" :class="{'error' : v?.post_status.$error}">
      <div class="deploy h-fit mb-10  wrap-item p-6 flex-col flex">
        <label class="flex mb-2 checkbox">
          <input class="mr-3" type="radio" id="deploy" v-model="product.post_status" value="publish">
          <span class="checkmark"></span>
          <label class="text-3xl" for="deploy">Publiceren</label>
        </label>
        <label class="flex mb-2 checkbox">
          <input class="mr-3" type="radio" id="draft" v-model="product.post_status" value="draft">
          <span class="checkmark"></span>
          <label class="text-3xl" for="draft">Concept</label>
        </label>
        <label class="flex mb-2 checkbox">
          <input class="mr-3" type="radio" id="draft" v-model="product.post_status" value="future">
          <span class="checkmark"></span>
          <label class="text-3xl" for="schedule">Inplannen</label>
        </label>
        <label v-if="product.post_status === 'future'" class="text-2xl flex mb-3 flex-col items-start">
          <h2 class="mb-3">Tidsplan for:</h2>
          <Datepicker locale="de" cancelText="Annuleer" selectText="Kies" v-model="product.post_date" placeholder="Kies datum"></Datepicker>
        </label>
        <button class="btn-save" ref="buttonFormSubmit" :class="{'loading': loading}" type="submit"
                form="product-submit">
          {{!props.loading ? 'Save' : 'Saving...'}}
        </button>
        <span class="absolute flex text-2xl text-rose-200 text-sm left-0 text-red-500  bg-rose-600 px-2"
              v-if="v?.post_status.$error">{{ v.post_status.$errors[0].$message }}</span>
      </div>
      <div class="categories mb-10 wrap-item flex-col flex  p-6 relative">
        <h2 class="label-title mb-4">Categorie</h2>
        <div class="flex mb-2 " v-for="(cat, id) in productCategories" :id="id">
          <label class="flex checkbox" :class="{'child' : cat.parent !== 0}">
            <input class="mr-3" type="checkbox" v-model="product.product_cat" :value="cat.term_id">
            <span class="checkmark"></span>
            <label class="text-2xl" for="deploy">{{ cat.name.replace('amp;','') }}</label>
          </label>
          <label class="checkbox-radio" v-if="product.product_cat.includes(cat.term_id)">
            <input type="radio" v-model="product.post_meta.rank_math_primary_product_cat" :value="cat.term_id"
                   class="ml-4">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="tags wrap-item flex-col flex mb-5 p-6 relative">
        <h2 class="label-title mb-4">Tags</h2>
        <div>
          <div class="flex mb-4 flex-col">
            <input type="text" class="w-5/6 mr-3 mb-3" v-model="newTag">
            <button type="button" class="btn-add-cat ml-auto"
                    @click.prevent="product.product_tag.push(newTag);productTags.all.push(newTag)">Toevoegen
            </button>
          </div>
          <div class="flex " v-for="(cat, id) in productTags.all" :id="id">
            <label class="flex  mb-2 checkbox" v-if="product.product_tag.includes(cat.term_id) || typeof cat === 'string'">
              <input class="mr-3" type="checkbox" v-model="product.product_tag"
                     :value="typeof cat === 'string' ? cat : cat.term_id">
              <span class="checkmark"></span>
              <label class="text-2xl" for="deploy">{{ typeof cat === 'string' ? cat : cat.name }}</label>
            </label>
          </div>
        </div>
      </div>
      <div class="tags  wrap-item flex-col flex mb-5 p-6 relative">
        <h2 class="label-title mb-4">Uitgelichte afbeelding</h2>
        <Upload @deleteProductImage="deleteProductImage" :productImage="product.product_img"
                @change="onUpload"></Upload>
      </div>
      <div class="tags  wrap-item flex-col flex mb-5 p-6 relative">
        <h2 class="label-title mb-4">Upload afbeeldingen</h2>
        <UploadGallery ref="gallery" @deleteImgGalley="deleteImgGallery"
                       :productGallery="product.product_gallery"></UploadGallery>
      </div>

    </div>

  </form>
</template>

<script setup>
// import Editor from '@tinymce/tinymce-vue';
import Editor                     from '@/components/FormComponents/Editor';
import Upload                     from "@/components/Upload.vue";
import UploadGallery              from "@/components/UploadGallery.vue";
import useValidate                from '@vuelidate/core'
import {required, helpers}        from "@vuelidate/validators";
import Main                       from '@/components/FormComponents/Main.vue'
import Evenement                  from '@/components/FormComponents/Evenement.vue'
import Excerpt                    from '@/components/FormComponents/Excerpt.vue'
import {ref, computed, onMounted} from 'vue'
import {useStore}                 from 'vuex';
import Until                      from '@/until/index';
import Datepicker                 from '@vuepic/vue-datepicker';
import Select2                    from 'vue3-select2-component';

const store = useStore();
const events = computed(() => store.state.events.events || []);
const attributes = computed(() => store.state.attributes.attributes || []);
const productCategories = computed(() => store.state.productCategories.productCategories || []);
const productTags = computed(() => store.state.productTags.productTags || []);
const {createUUID, stringToSlug} = Until;
const termsInAttrs = ref({})
const newTag = ref();
const gallery = ref();


onMounted(async () => {
  store.dispatch('events/getEvents')
  store.dispatch('productCategories/getProductCategories')
  store.dispatch('productTags/getProductTags')
  const data = await store.dispatch('attributes/getAttributes')
  for (let i in data) {
    const key = 'pa_' + data[i].attribute_name;
    termsInAttrs.value[key] = data[i].attribute_term.map((term) => {
      return {id: term.term_id, text: term.name}
    })
    setAttrsForVariations(props.product.attribute_terms[key], key)
  }

});

const props = defineProps({
  product: {
    type    : Object,
    required: true,
  },
  loading: {
    type: Boolean,
  }
});

const productSetting = ref(1);
const selectedAttribute = ref('custom');
const emit = defineEmits(['formSubmit', 'addAttrToProductsAttributes', 'addVariationToProduct', 'deleteProductImage', 'deleteImgGallery']);
const attrsForVariations = ref({});
const newProductImage = ref(null);
const buttonFormSubmit = ref();

function setAttrsForVariations(val, key)
{
  if (!val) return
  val = val.map((el) => Number(el))
  attrsForVariations.value[key] = termsInAttrs.value[key]
      .filter((item) => val.includes(item.id))
      .map((el) => {
        return {id: el.text.toLowerCase(), text: el.text};
      });
}

function deleteProductImage()
{
  emit('deleteProductImage')
}

function deleteImgGallery(id)
{
  emit('deleteImgGallery', id)
}

const stockSelectData = [
  {
    id: 'instock', label: 'Op voorraad'
  },
  {
    id: 'outofstock', label: 'Uitverkocht'
  },
  {
    id: 'onbackorder', label: 'In nabestelling'
  },
]
const stockBackorders = [
  {
    id: 'no', label: 'Niet toestaan'
  },
  {
    id: 'notify', label: 'Toestaan, maar klant informeren'
  },
  {
    id: 'yes', label: 'Toestaan'
  },
]

const rules = computed(() => {
  return {
    post_status: {required: helpers.withMessage(({$model}) => 'Post status is required', required)},
    post_title : {required}
  }
})

const v = useValidate(rules, props.product);

function onUpload(file)
{
  newProductImage.value = file;
}
async function formSubmit()
{
  await v.value.$validate()
  if (!v.value.$error) {
    if (!props.product.ID) {
      buttonFormSubmit.value.classList.add('disabled');
    }
    emit('formSubmit', props.product, newProductImage.value, gallery.value.files)
  }
}

function addTo()
{
  props.product.post_meta.opsomming.push({id: createUUID(), value: ''})
}

function addAttrToProductAttributes()
{
  const emptyAttr = {name: "", value: "", position: 0, is_visible: 0, is_variation: 0, is_taxonomy: 0};
  if (selectedAttribute.value.attribute_id) {
    emptyAttr.name = selectedAttribute.value.attribute_name.includes('pa_') ? selectedAttribute.value.attribute_name : 'pa_' + selectedAttribute.value.attribute_name
    emptyAttr.is_taxonomy = 1
  }
  emit('addAttrToProductsAttributes', emptyAttr)
}

function addVariationToProduct()
{
  const emptyAttr = {};
  emit('addVariationToProduct', emptyAttr)
}

function removeAttrFromProduct(key)
{
  delete props.product.post_meta._product_attributes[key]
}

</script>


<style scoped lang="scss">
#product-submit {
  @media (max-width: 1300px) {
    flex-direction: column;
    & .deploy {
      order: 100;
    }
    & .left {
      width: 100%;
      margin-bottom: 50px;
    }
    & .right {
      margin-bottom: 100px;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: center;
      & > div {
        margin-right: 20px;
        max-width: 30%;
        width: 100%;
        height: fit-content;
        min-width: 231px;
      }
    }
  }
  @media (max-width: 768px) {
    & .right {
      flex-direction: column;
      & > div {
        margin-right: 0;
        max-width: 100%;
      }
    }
  }
}
.btn-add-cat {
  width: 79px;
  padding: 0 5px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;

  color: #1f1f1f;
  font-size: 14px;
  font-weight: 400;
}
.label-title {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
}


.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.variations-attrs {
  flex-wrap: wrap;
  & > div {
    margin-bottom: 10px;
  }
}

.add-info {
  @media (max-width: 768px) {
    flex-direction: column;
    display: flex;
  }
  & > div:first-child {
    width: 200px;
    margin-right: 10px;
    @media (max-width: 1300px) {
      width: 130px;
    }
    @media (max-width: 768px) {
      flex-direction: row;
      & button {
        margin-right: 15px;
        width: 100px;
        min-width:100px;
      }
    }
    @media (max-width: 500px) {
      flex-direction: column;
      width: 100%;
      & button {
        margin-right: 0px;
        width: 100%;
      }
    }
  }
  & > div:nth-child(2) {
    @media (max-width: 768px) {
     width: 100%;
    }
  }
  & label {
    color: #393939 ;
    font-size: 14px ;
    font-weight: 500 ;
  }
}

label {
  color: #1f1f1f;
  font-size: 16px ;
  font-weight: 400 ;
  text-align: left;
}
.btn-save {
  width: 155px;
  height: 42px;
  background-color: #15b653;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: 0.8s;

  &:hover {
    border: 2px solid #15b653;
    background-color: white;
    color: #15b653;
  }

  &.loading {
    position: relative;
    border: 2px solid #15b653;
    background-color: white;
    color: #15b653;
    &:after {
      content: '';
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-image: url("../assets/loader.gif");
      background-size: 30px 30px;
    }
  }
}
.right .deploy  {
  & .checkbox.schedule {
    position: relative;
    padding-left: 25px!important;
    cursor: pointer;
    & input:not([type="radio"]) {
      display: none;
      &:checked ~ .checkmark{
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        background-color: transparent;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);
        background-image: url("../assets/point.svg");
      }
    }
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;


      cursor: pointer;



      border: 1px solid #8c8f94;
      border-radius: 4px;
      background: #fff;
      color: #50575e;
      clear: none;
      display: inline-block;
      line-height: 0;

      outline: 0;
      padding: 0!important;
      text-align: center;
      vertical-align: middle;

      -webkit-appearance: none;
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
      transition: .05s border-color ease-in-out;
    }
  }
}
.right .categories{
  & .checkbox {
    position: relative;
    padding-left: 25px!important;
    cursor: pointer;
    & input:not([type="radio"]) {
      display: none;
      &:checked ~ .checkmark{
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);
        background-image: url("../assets/point.svg");
      }
    }
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;


      cursor: pointer;



      border: 1px solid #8c8f94;
      border-radius: 4px;
      background: #fff;
      color: #50575e;
      clear: none;
      display: inline-block;
      line-height: 0;

      outline: 0;
      padding: 0!important;
      text-align: center;
      vertical-align: middle;

      -webkit-appearance: none;
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
      transition: .05s border-color ease-in-out;
    }
  }
}

.left, .right .deploy, .right .tags {
  & .checkbox {
    position: relative;
    padding-left: 25px!important;
    cursor: pointer;
    & input {
      display: none;
      &:checked ~ .checkmark{
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left:3px;
        transform: translateY(-50%);
        background-color: #707070;
      }
    }
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border: 1px solid #707070;
    }
  }
}

.right .tags {
  & .checkbox {
    position: relative;
    padding-left: 25px !important;
    cursor: pointer;

    & input {
      display: none;

      &:checked ~ .checkmark {
        display: none;
      }
    }

    &:before {
      border: none;
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-image: url("../assets/icon-cancel.svg");
      background-size: 18px 18px;
    }
  }
}

.wrap {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.wrap-item {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 5px;
  border: 1px solid #707070;
  padding: 9px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.btn-attr-remove {
  @media (max-width: 768px) {
    margin-left: 0;
  }
}
.variation-col {
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    & label {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      & input {
        width: 100%;
      }
    }
  }
}
.label-col-mobile {
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & textarea {
      width: 100%;
    }
    & > div, input , select, button{
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.btn-nav {
  width: 183px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #001948;
  justify-content: center;
  align-items: center;
  color: #001948;
  font-size: 16px;
  font-weight: 700;
  transition: 0.5s;

  @media (max-width: 1300px) {
    width: 121px;
    font-size: 12px;
  }

  &.active {
    background-color: #001948;
    color: white;
  }

  &:hover {
    background-color: #001948;
    color: white;
  }
}

.flex-grow {
  flex-grow: 1;
}

.child {
  margin-left: 20px;
}

h3 {
  margin: 40px 0 0;
}

.btn-add {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #001948;
  justify-content: center;
  align-items: center;
  color: #001948;
  font-size: 14px;
  font-weight: 700;
  transition: 0.5s;
  &:hover {
    background-color: #001948;
    color: white;
  }
}
.product-settings {
  @media (max-width: 768px) {
    & > div:first-child{
      flex-direction: column;
    }
    & select {
      margin-left: 0;
    }
  }
}
.select2-container {
  width: 100% !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

.form-control {
  position: fixed !important;
}

input:not([type="radio"]), select {
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  height: 30px;

  color: #393939 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;
  width: 100%;
  max-width: 300px !important;

  padding-left: 5px !important;
}
textarea {
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding-left: 5px !important;
  color: #393939 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;


}
.checkbox-radio{
  position: relative;
  padding-left: 25px!important;
  cursor: pointer;
  margin-left: 7px;
  & input {
    display: none;
    &:checked ~ .checkmark{
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left:3px;
      transform: translateY(-50%);
      background-color: #707070;
    }
  }
  &:before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border: 1px solid #707070;
  }
}
li {
  display: inline-block;
  margin: 0 10px;
}

.error {
  border: 1px solid red;
}

a {
  color: #42b983;
}

.left {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  padding-bottom: 60px;
}

.right {
  height: fit-content;
  width: 231px;
  margin-bottom: 150px;
  min-width: 231px;
  & .label-title {
    border-bottom: 1px solid #707070;
    margin-left: -9px;
    margin-right: -9px;
    margin-bottom: 9px;
    padding-left: 9px;
    margin-top: -9px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  & label {
    font-size: 13px;
  }
}
</style>
