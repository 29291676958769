function replaceLineBreaks(data)
{
    const searchRegExp = /\n/g;
    const replaceWith = '-';
    return data.replace(searchRegExp, "<br>");
}

function createUUID() {
    let dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
}

function stringToSlug(str)
{
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,;";
    var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn-----";

    for (var i=0, l=from.length ; i<l ; i++)
    {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace('.', '-')
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .replace( /\//g, '' );
    return str;
}

export default {
    replaceLineBreaks,
    createUUID,
    stringToSlug
}
