import EventCategoriesService from '@/services/eventCategories.service'

export const eventCategories = ({
    namespaced: true,
    state: {
        eventCategories : [],
    },
    getters: {
    },
    mutations: {
        SET_EVENT_CAT (state, data) {
            state.eventCategories = data
        },
    },
    actions: {
        getEventCategories({commit}) {
            return EventCategoriesService.getAllEventCategories().then((response) => {
                commit('SET_EVENT_CAT', response.data)
                return Promise.resolve(response.data)
            });
        },
    },
    modules: {
    }
})
