import ProductCategoriesService from '@/services/productCategories.service'

export const productCategories = ({
    namespaced: true,
    state: {
        productCategories : [],
    },
    getters: {
    },
    mutations: {
        SET_PROD_CAT (state, data) {
            state.productCategories = data
        },
    },
    actions: {
        getProductCategories({commit}) {
            return ProductCategoriesService.getAllProductCategories().then((response) => {
                commit('SET_PROD_CAT', response.data)
                return Promise.resolve(response.data)
            });
        },
    },
    modules: {
    }
})
