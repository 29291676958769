import EventsService from '@/services/events.service'

export const events = ({
    namespaced: true,
    state: {
        events : [],
    },
    getters: {
    },
    mutations: {
        SET_EVENTS (state, data) {
            state.events = data
        },
    },
    actions: {
        getEvents({commit}) {
            return EventsService.getAllEvents().then((response) => {
                commit('SET_EVENTS', response.data)
                return Promise.resolve(response.data)
            });
        },
        getEvent({commit},id) {
            return EventsService.getEvent(id).then((response) => {
                return Promise.resolve(response.data)
            });
        }
    },
    modules: {
    }
})
