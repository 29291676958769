<template>

  <form @submit.prevent="formSubmit" class="flex mt-10" id="event-submit">

    <div class="left w-5/6 mr-7">

      <Main @update:content="eventItem.post_content = $event" @update:title="eventItem.post_title = $event"
            :content="eventItem.post_content" :title="eventItem?.post_title" :error="v?.post_title.$error"></Main>

      <div class="text-2xl flex mb-5 flex-col mt-12">
        <h2 class="label-title ">Ticket quantity limitation</h2>
        <div class="mt-2">
          <label class="flex mb-2 checkbox">
            <input class="mr-3" type="radio" id="open_ended" v-model="eventItem.post_meta.limit_level" :value="0">
            <span class="checkmark"></span>
            <label class="text-2xl" for="open_ended">Per ticket type</label>
          </label>
          <label class="flex mb-2 checkbox">
            <input class="mr-3" type="radio" id="range" v-model="eventItem.post_meta.limit_level" :value="1">
            <span class="checkmark"></span>
            <label class="text-2xl" for="range">Per event</label>
          </label>
        </div>
        <label v-if="eventItem.post_meta.limit_level == 1" class="text-2xl mt-4 flex mb-5">
          <input type="text" v-model="eventItem.post_meta.limit_level_value">
        </label>
      </div>

      <div>
        <h2 class="text-3xl text-left mb-5 label-title">Event terms and conditions</h2>
        <Editor @update:content="eventItem.post_meta.event_terms = $event" :height="300"
                :content="eventItem.post_meta.event_terms"></Editor>
      </div>
    </div>

    <div class="right h-fit" :class="{'error' : v?.post_status.$error}">
      <div class="deploy h-fit mb-5  wrap-item relative p-6 flex-col flex">
        <label class="flex mb-2 checkbox">
          <input class="mr-3" type="radio" id="deploy" v-model="eventItem.post_status" value="publish">
          <span class="checkmark"></span>
          <label class="text-3xl" for="deploy">Publiceren</label>
        </label>
        <label class="text-2xl flex mt-5 mb-5 flex-col items-start">
          <h2 class="label-title mb-4">Start date & time</h2>
          <Datepicker locale="de" cancelText="Annuleer" selectText="Kies" v-model="eventItem.post_meta.event_date_time" placeholder="Kies datum"></Datepicker>
        </label>

        <label class="text-2xl flex mb-5 flex-col items-start">
          <h2 class="label-title mb-4"> End date & time </h2>
          <Datepicker locale="de" cancelText="Annuleer" selectText="Kies" v-model="eventItem.post_meta.event_end_date_time" placeholder="Kies datum"></Datepicker>
        </label>
        <button class="btn-save" ref="buttonFormSubmit" type="submit" :class="{'loading': loading}" form="event-submit">
          {{!props.loading ? 'Save' : 'Saving...'}}
        </button>
        <span class="absolute flex text-2xl text-rose-200 text-sm left-0 text-red-500  bg-rose-600 px-2"
              v-if="v?.post_status.$error">{{ v.post_status.$errors[0].$message }}</span>
      </div>

      <div class="categories mb-5 wrap-item flex-col flex  p-6 relative">
        <h2 class="label-title mb-4">Categorie</h2>
        <div>
          <label class="flex mb-2 checkbox" :class="{'child' : cat.parent !== 0}" v-for="(cat, id) in eventCategories" :id="id">
            <input class="mr-3" type="checkbox" v-model="eventItem.event_cat" :value="cat.term_id">
            <span class="checkmark"></span>
            <label class="text-2xl" for="deploy">{{ cat.name }}</label>
          </label>
        </div>
      </div>
    </div>

  </form>

</template>

<script setup>
import Editor                     from '@/components/FormComponents/Editor';
import useValidate                from '@vuelidate/core'
import {required, helpers}        from "@vuelidate/validators";
import Main                       from '@/components/FormComponents/Main.vue'
import {ref, computed, onMounted} from 'vue'
import {useStore}                 from 'vuex';
import Until                      from '@/until/index';
import Datepicker                 from '@vuepic/vue-datepicker';
import Select2                    from 'vue3-select2-component';

const store = useStore();
const eventCategories = computed(() => store.state.eventCategories.eventCategories || []);
const {createUUID, stringToSlug} = Until;
const buttonFormSubmit = ref();

onMounted(async () => {
  store.dispatch('eventCategories/getEventCategories')
});

const props = defineProps({
  eventItem: {
    type    : Object,
    required: true,
  },
  loading: {
    type: Boolean,
  }
});

const emit = defineEmits(['formSubmit']);

const rules = computed(() => {
  return {
    post_status: {required: helpers.withMessage(({$model}) => 'Post status is required', required)},
    post_title : {required}
  }
})

const v = useValidate(rules, props.eventItem);


async function formSubmit()
{
  await v.value.$validate()
  if (!v.value.$error) {
    if (!props.eventItem.ID) {
      buttonFormSubmit.value.classList.add('disabled');
    }
    emit('formSubmit', props.eventItem)
  }
}

</script>


<style scoped lang="scss">
#event-submit {
  @media (max-width: 1300px) {
    flex-direction: column;
    & .left {
      width: 100%;
      margin-bottom: 50px;
    }
    & .right {
      margin-bottom: 100px;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: center;
      & > div {
        margin-right: 20px;
        max-width: 30%;
        width: 100%;
        height: fit-content;
        min-width: 231px;
      }
    }
  }
  @media (max-width: 768px) {
    & .right {
      flex-direction: column;
      & > div {
        margin-right: 0;
        max-width: 100%;
      }
    }
  }
}
.label-title {
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 5px;
}

label {
  color: #1f1f1f;
  font-size: 16px ;
  font-weight: 400 ;
  text-align: left;
}

.btn-save {
  width: 155px;
  height: 42px;
  background-color: #15b653;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;

  border: 2px solid transparent;
  transition: 0.8s;

  &:hover {
    border: 2px solid #15b653;
    background-color: white;
    color: #15b653;
  }

  &.loading {
    position: relative;
    border: 2px solid #15b653;
    background-color: white;
    color: #15b653;
    &:after {
      content: '';
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-image: url("../assets/loader.gif");
      background-size: 30px 30px;
    }
  }
}


.checkbox {
    position: relative;
    padding-left: 25px!important;
    cursor: pointer;
    & input {
      display: none;
      &:checked ~ .checkmark{
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left:3px;
        transform: translateY(-50%);
        background-color: #707070;
      }
    }
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      border: 1px solid #707070;
    }
  }

.wrap {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.wrap-item {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 5px;
  border: 1px solid #707070;
  padding: 9px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.flex-grow {
  flex-grow: 1;
}

.child {
  margin-left: 20px;
}


h3 {
  margin: 40px 0 0;
}

.select2-container {
  width: 100% !important;
}

ul {
  list-style-type: none;
  padding: 0;
}

.form-control {
  position: fixed !important;
}

input:not([type="radio"]), select {
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  height: 30px;

  color: #393939 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;
  width: 100%;
  max-width: 300px !important;

  padding-left: 5px !important;
}

textarea {
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding-left: 5px !important;
  color: #393939 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: left;


}

li {
  display: inline-block;
  margin: 0 10px;
}

.error {
  border: 1px solid red;
}

a {
  color: #42b983;
}

.left {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  padding-bottom: 60px;
}

.right {
  height: fit-content;
  width: 231px;
  min-width: 231px;
  & .categories {
    & .label-title {
      border-bottom: 1px solid #707070;
      margin-left: -9px;
      margin-right: -9px;
      margin-bottom: 9px;
      padding-left: 9px;
      margin-top: -9px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    & label {
      font-size: 13px;
    }
  }

}

.right .categories{
  & .checkbox {
    position: relative;
    padding-left: 25px!important;
    cursor: pointer;
    & input:not([type="radio"]) {
      display: none;
      &:checked ~ .checkmark{
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);
        background-image: url("../assets/point.svg");
        background-color: transparent;
        border-radius: 0;
      }
    }
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      cursor: pointer;
      border: 1px solid #8c8f94;
      border-radius: 4px;
      background: #fff;
      color: #50575e;
      clear: none;
      display: inline-block;
      line-height: 0;

      outline: 0;
      padding: 0!important;
      text-align: center;
      vertical-align: middle;

      -webkit-appearance: none;
      box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
      transition: .05s border-color ease-in-out;
    }
  }
}
</style>
