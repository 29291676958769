<template>
  <div v-if="!productImage?.guid && !preview" class="flex bg-white shadow-lg rounded-lg border-4 border-white">
    <button  @click.prevent="onAppend" class="btn-upload  p-2  bg-gray-100 rounded-lg w-full"
            type="submit">
                         <span class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                        </svg>
                        Upload bestand
                         </span>

    </button>
  </div>
  <ul id="gallery" v-show="preview || productImage" class="flex flex-1 flex-wrap -m-1 pt-2">
    <li v-if="productImage?.guid">
      <article tabindex="0" class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm">
        <img alt="upload preview" class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed">
        <img :src="productImage?.guid"/>
        <button @click="deleteProductImage()"
                class="btn-delete">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
               id="Capa_1" x="0px" y="0px" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" fill="white"
               xml:space="preserve">
<g>
	<path style="fill:red;"
        d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25   C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0   L13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467   L7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468   c0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467   C19.033,16.725,19.033,17.138,18.78,17.394z"/>
</g>
</svg>
        </button>
      </article>
    </li>
    <li v-else-if="preview"  class="block p-1">
      <article tabindex="0" class="group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm">
        <img alt="upload preview" class="img-preview hidden w-full h-full sticky object-cover rounded-md bg-fixed">
        <img :src="preview"/>
        <button @click="deleteFile()"
                class="btn-delete">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
               id="Capa_1" x="0px" y="0px" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" fill="white"
               xml:space="preserve">
<g>
	<path style="fill:red;"
        d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25   C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0   L13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467   L7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468   c0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467   C19.033,16.725,19.033,17.138,18.78,17.394z"/>
</g>
</svg>
        </button>
      </article>
    </li>
  </ul>
  <input
      class="upload pointer-events-none"
      type="file"
      style="z-index: 1"
      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
      ref="uploadInput"
      @change="onPreviewFile"
  />
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import axios                         from "axios";

const preview = ref(null)
const file = ref(null)
const showUpload = ref(false)
const uploadInput = ref(null)
const errorMsg = ref("")
const info = ref(null)

const props = defineProps({
  max      : {type: Number, default: 10},
  uploadMsg: String,
  maxError : String,
  fileError: String,
  clearAll : String,
  productImage: Object
})

const emit = defineEmits(['change','deleteProductImage'])

function onAppend()
{
  uploadInput.value.click();
}

function onUpload()
{
  emit('change', file.value);
}

function deleteProductImage(id)
{
  emit('deleteProductImage', id);
}


function onPreviewFile(event)
{
  file.value = event.currentTarget.files[0];
  if (!file.value) return;
  Promise.all([readAsDataURL(file.value)]).then((values) => {
    preview.value = values;
    onUpload();
  });

}

function readAsDataURL(file)
{
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    fr.onload = function () {
      resolve(fr.result);
    };
    fr.onerror = function () {
      reject(fr);
    };
    fr.readAsDataURL(file);
  });
}

function deleteFile(index)
{
  preview.value = null;
  file.value = null;
  uploadInput.value.value = null;
}


</script>

<style lang="scss">
.btn-upload {
  border: 1px solid #001948;
  color: #001948;
  font-size: 14px;
  transition: 0.5s;

&:hover {
   background-color: #001948;
   color: white;
 }
}
.btn-delete {
  position: absolute;
  bottom: 4px;
  right:4px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 5px;
& svg {
    width: 24px;
    height: 24px;
  }
}
.upload {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
  z-index: 99;
}
</style>
