import ProductsService from '@/services/products.service'

export const products = ({
    namespaced: true,
    state: {
        products : [],
        product : null,
    },
    getters: {
    },
    mutations: {
        SET_PRODUCTS (state, data) {
            state.products = data
        },
        SET_PRODUCT_FILES(state, file) {
            state.product.product_gallery.push(file)
        },
        SET_ACTIVE_PRODUCT(state,product) {
            state.product = product;
        }
    },
    actions: {
        getProducts({commit}) {
            return ProductsService.getAllProducts().then((response) => {
                commit('SET_PRODUCTS', response.data)
                return Promise.resolve(response.data)
            });
        },
        getProduct({commit},id) {
            return ProductsService.getProduct(id).then((response) => {
                commit('SET_ACTIVE_PRODUCT', response.data)
                return Promise.resolve(response.data)
            });
        }
    },
    modules: {
    }
})
