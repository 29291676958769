import productTagsService from '@/services/productTags.service'

export const productTags = ({
    namespaced: true,
    state: {
        productTags : [],
    },
    getters: {
    },
    mutations: {
        SET_PROD_TAGS (state, data) {
            state.productTags = data
        },
    },
    actions: {
        getProductTags({commit}) {
            return productTagsService.getAllProductTags().then((response) => {
                commit('SET_PROD_TAGS', response.data)
                return Promise.resolve(response.data)
            });
        },
    },
    modules: {
    }
})
