import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductsView from '../views/ProductsView.vue'
import EventsView from '../views/EventsView.vue'
import Product from '../views/Product.vue'
import Event from '../views/Event.vue'
import ProductAdd from '../views/ProductAdd.vue'
import EventAdd from '../views/EventAdd.vue'
import ExportEvents from '../views/ExportEvents.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: HomeView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: ProductsView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/events',
    name: 'Events',
    component: EventsView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: Product,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: Event,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/product/add',
    name: 'ProductAdd',
    component: ProductAdd,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/event/add',
    name: 'EventAdd',
    component: EventAdd,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/exportEvents',
    name: 'ExportEvents',
    component: ExportEvents,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'AppLayoutAuth',
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiredLogin)) {
    if (!store.state.user.isLoginIn){
      return next('/login');
    } else {
      if (!store.state.user.checked) {
        const userCheck = await store.dispatch('user/checkLogin');
        store.commit('user/CHECK_USER', !!userCheck);
        if (!userCheck) {
          return next('/login')
        }
      }
      return  next();
    }
  }

  return next();
})



export default router
