<template>
  <header class="flex justify-between shadow-lg shadow-indigo-300/50">
    <div class="logo flex justify-between items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="277" height="38" viewBox="0 0 277 38">
        <g>
          <g>
            <g>
              <path fill="#fff"
                    d="M115.995 30.511v.541h-8.091v-.564c.215 0 .44.003.665 0 .556-.007 1.087-.085 1.514-.496.289-.306.45-.71.451-1.131.04-.53.053-1.062.053-1.593.004-4.563.005-9.127.003-13.69v-.304c-.176 0-.33-.002-.482 0-.892.016-1.783.034-2.675.049-1.819.03-2.61 1.466-2.75 2.603-.01.114-.03.226-.06.336a.437.437 0 0 1-.61.328l.218-4.515h15.573l.21 4.522c-.485-.02-.478-.022-.593-.435a16.004 16.004 0 0 0-.313-1.023 2.551 2.551 0 0 0-2.238-1.756c-.765-.1-1.546-.083-2.32-.107-.393-.013-.785-.003-1.195-.003-.006.085-.016.155-.016.226.001 4.916.004 9.832.01 14.748.015.357.057.713.126 1.064.123.79.735 1.044 1.273 1.12.402.055.812.054 1.247.08z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M125.522 23.722l3.569-7.49.133.002 3.458 7.488zm15.861 7.3c-.156-.09-.244-.15-.339-.194a3.432 3.432 0 0 0-.523-.22c-1.343-.367-2.144-1.304-2.701-2.517-2.127-4.628-4.256-9.255-6.388-13.88-.417-.904-.848-1.8-1.32-2.798a6.178 6.178 0 0 0-.673.91c-1.493 3.143-2.964 6.298-4.448 9.446-1.029 2.182-2.063 4.36-3.104 6.537-.522 1.092-1.27 1.948-2.476 2.32-.234.092-.46.202-.678.328l.044.115h6.865l.028-.124a6.252 6.252 0 0 0-.638-.288c-.384-.126-.783-.206-1.164-.338-.644-.224-.853-.632-.692-1.297.037-.2.093-.394.168-.583.542-1.182 1.09-2.361 1.657-3.532.043-.09.246-.143.374-.144 2.44-.008 4.88-.003 7.32-.01a.57.57 0 0 1 .62.4c.135.354.33.683.478 1.032.357.846.811 1.671 1.025 2.553.285 1.175.407 1.194-.897 1.748-.356.152-.725.273-1.088.408l.018.1a74.17 74.17 0 0 0 8.532.027z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M143.357 31.025v-6.519l.12-.058c.166.163.312.346.433.545.12.302.216.615.285.933.497 1.872 1.783 2.988 3.543 3.583a5.904 5.904 0 0 0 4.619-.261 2.705 2.705 0 0 0 .37-4.666c-1.354-.886-2.797-1.643-4.223-2.415a25.848 25.848 0 0 1-3.681-2.263 4.314 4.314 0 0 1-.048-6.793 6.242 6.242 0 0 1 5.001-1.356 31.96 31.96 0 0 1 3.22.807c.911.24 1.061.193 1.644-.547.133-.17.291-.322.518-.568 0 .358-.003.602 0 .846.023 1.526.061 3.051.06 4.576-.027.463-.084.923-.17 1.378l-.15.011c-.175-.526-.387-1.044-.52-1.58-.68-2.776-3.34-4.072-5.618-3.894-1.21.095-2.317.474-2.946 1.655-.557 1.047-.347 2.046.536 2.829 1.285 1.14 2.801 1.886 4.33 2.615a22.787 22.787 0 0 1 3.926 2.213c1.25.934 1.954 2.154 1.96 3.711.007 1.665-.808 2.922-2.132 3.873-1.447 1.04-3.094 1.271-4.825 1.207-1.116-.041-2.152-.409-3.214-.679a45.157 45.157 0 0 0-1.483-.354.551.551 0 0 0-.648.228c-.237.343-.53.648-.798.97a17.263 17.263 0 0 1-.109-.027z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M26.366 1.664l.723 9.609a2.942 2.942 0 0 1-.591-.012c-.089-.019-.183-.15-.219-.251a15.308 15.308 0 0 0-1.807-3.646 9.122 9.122 0 0 0-5.755-3.802c-2.977-.659-5.813-.29-8.433 1.304C8.256 6.1 7.045 7.994 6.25 10.177a15.212 15.212 0 0 0-.914 4.52c-.038.82-.131 1.64-.112 2.458.048 2.089.344 4.133 1.227 6.06 1.286 2.806 3.46 4.568 6.374 5.466 1.51.449 3.086.632 4.659.542a10.309 10.309 0 0 0 5.955-2.05 19.695 19.695 0 0 0 2.95-2.891l.74-.86.725.49c-.548.719-1.055 1.447-1.626 2.122a13.706 13.706 0 0 1-5.263 3.98c-1.153.476-2.37.773-3.612.881-1.22.133-2.448.177-3.674.132-3.183-.192-6.123-1.125-8.609-3.21A13.663 13.663 0 0 1 .355 19.98c-1.7-7.669 2.865-15.335 10.417-17.493 1.873-.57 3.832-.8 5.785-.681 1.707.096 3.385.481 4.963 1.139.626.26 1.253.53 1.9.728a1.353 1.353 0 0 0 1.57-.653c.168-.276.303-.571.47-.848a.93.93 0 0 1 .906-.508z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M90.383 21.312c-.009-.162-.02-.282-.02-.402-.001-2.355.005-4.71-.008-7.066-.002-.312.091-.434.4-.462.645-.059 1.287-.205 1.931-.213 2.018-.026 4.23.825 4.6 3.584.218 1.621-.742 3.193-2.317 3.93-.866.387-1.8.598-2.748.624-.596.03-1.195.005-1.838.005zm5.325.686c.84-.33 1.63-.579 2.363-.943 2.471-1.224 3.16-3.435 2.394-5.58-.535-1.495-1.768-2.316-3.23-2.813-1.65-.56-3.372-.512-5.073-.558-2.287-.061-4.577-.024-6.866-.005a5.2 5.2 0 0 0-1.017.21l.01.134c.41.07.817.156 1.229.208 1.367.17 1.888.744 1.89 2.11.004 2.207.006 4.415.005 6.622 0 2.48.003 4.96-.009 7.44a1.585 1.585 0 0 1-1.043 1.593c-.324.088-.656.14-.992.156-.373.054-.769.059-1.064.502h9.315c-.458-.437-.938-.462-1.395-.498-1.182-.092-1.792-.725-1.794-1.92-.003-1.84-.002-3.68-.008-5.52 0-.264-.032-.53-.051-.826.625-.03 1.22-.047 1.812-.092.23-.028.453.09.56.295a45.37 45.37 0 0 0 7.18 8.267 32.268 32.268 0 0 0 8.437 5.55c1.028.455 2.094.824 3.143 1.233-7.25-3.196-12.343-8.535-15.796-15.565z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M54.286 13.474c.317-.045.618-.105.923-.13 1.381-.11 2.742-.123 3.915.806a4.22 4.22 0 0 1 1.512 2.566 4.128 4.128 0 0 1-.793 3.52c-.945 1.205-2.228 1.565-3.686 1.46-.51-.037-1.015-.152-1.525-.202-.256-.024-.352-.112-.35-.376.007-2.52.004-5.04.004-7.644zM48.19 12.09a.82.82 0 0 0 .774.557c.295.041.598.035.89.089.811.15 1.193.425 1.323 1.112.092.437.143.881.151 1.327-.011 4.577-.038 9.153-.079 13.73-.005.549-.143 1.117-.722 1.359-.4.147-.817.247-1.24.3-.386.063-.779.077-1.093.488h9.145l.023-.126a2.914 2.914 0 0 0-.575-.264c-.382-.085-.773-.124-1.161-.176-.644-.087-1.022-.385-1.117-1.03a22.804 22.804 0 0 1-.281-2.742c-.033-1.277.032-2.556.054-3.834.003-.202 0-.405 0-.514 1.376.09 2.725.23 4.076.248a6.655 6.655 0 0 0 3.378-.889 4.927 4.927 0 0 0 2.51-4.89c-.22-1.71-1.239-2.874-2.705-3.69-1.388-.772-2.922-1.008-4.466-1.039-2.942-.057-5.886-.016-8.885-.016z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M68.243 23.759L71.9 16.09l3.534 7.668zm15.669 7.237a18.715 18.715 0 0 1-.006-.108c-.434-.186-.87-.37-1.303-.56-.673-.293-1.265-.677-1.592-1.376-1.279-2.735-2.575-5.462-3.839-8.204-1.333-2.89-2.633-5.796-3.964-8.687a.714.714 0 0 0-.475-.357c-.116-.016-.32.198-.394.353-1.15 2.391-2.284 4.79-3.422 7.188a5118.116 5118.116 0 0 1-4.254 8.959c-.522 1.096-1.199 2.033-2.458 2.379a3.441 3.441 0 0 0-.637.325c2.256.264 4.535.273 6.793.026a3.368 3.368 0 0 0-.678-.287 11.569 11.569 0 0 1-1.298-.445.876.876 0 0 1-.486-1.216c.525-1.247 1.114-2.468 1.644-3.713.18-.423.38-.566.88-.544 1.511.064 3.025.033 4.538.025.91-.005 1.82-.044 2.73-.046.09 0 .215.138.265.24.238.486.477.974.676 1.477.365.927.71 1.862 1.049 2.798.16.446-.068.77-.426.99-.261.142-.534.26-.816.354-.284.112-.573.21-.859.315l.024.112a60.53 60.53 0 0 0 8.308.002z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M32.171 23.731l3.54-7.487.14-.02 3.474 7.507zm7.239 7.152l.015.112c2.773.193 5.557.173 8.327-.06a6.93 6.93 0 0 0-1.107-.523 3.225 3.225 0 0 1-1.803-1.617c-.198-.372-.4-.744-.575-1.126a9094.43 9094.43 0 0 0-5.772-12.547c-.554-1.202-1.13-2.394-1.757-3.723a12.16 12.16 0 0 0-.837 1.295c-1.741 3.672-3.458 7.355-5.193 11.03-.77 1.629-1.553 3.251-2.35 4.867a3.71 3.71 0 0 1-2.199 1.994 6.017 6.017 0 0 0-.767.378c2.273.213 4.562.205 6.835-.024a2.55 2.55 0 0 0-.618-.293c-.41-.135-.84-.224-1.234-.394-.587-.254-.734-.593-.558-1.203.126-.469.284-.928.473-1.375.397-.878.846-1.733 1.25-2.609a.444.444 0 0 1 .476-.315c2.412.013 4.824.013 7.236-.001a.594.594 0 0 1 .557.31 14.522 14.522 0 0 1 1.798 4.074c.197.673-.082.88-.495 1.132a4.189 4.189 0 0 1-.792.307c-.302.108-.607.207-.91.31z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M238.011 12.62c1.207 0 2.407-.202 3.505.045 1.898.43 2.737 1.74 2.909 3.708.132 1.521-.899 3.078-2.542 3.638-1.203.41-2.507.52-3.872.783zm13.214 17.792c-4.103-2.057-5.686-6.194-8.317-9.495.742-.312 1.37-.564 1.99-.837 1.865-.824 2.848-2.429 2.65-4.312-.19-1.801-1.757-3.549-3.596-3.857a26.844 26.844 0 0 0-3.807-.363c-2.468-.055-4.94-.016-7.253-.016.34.147.92.285 1.35.616.413.317.872.798.959 1.271.152 1.01.21 2.033.172 3.054-.024 3.788-.115 7.575-.102 11.363.004 1.083-.41 1.717-1.371 2.08-.252.095-.495.211-.886.38h7.065a7.573 7.573 0 0 1-1.136-.63c-.311-.254-.765-.6-.774-.915-.063-2.464-.035-4.931-.035-7.436 1.125.104 2.13.378 2.895 1.485 1.63 2.361 3.393 4.632 5.124 6.922.179.236.441.396.734.448 1.443.114 2.891.168 4.338.242z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M203.202 20.842c-.219 1.509-.337 3.041-.682 4.521a5.876 5.876 0 0 1-2.845 3.779c-2.43 1.432-5.281.656-6.938-1.866a9.535 9.535 0 0 1-1.507-4.802c-.116-2.068-.153-4.127.512-6.13.919-2.764 2.914-4.38 5.464-4.287 1.963.072 3.563.86 4.521 2.689.999 1.906 1.386 3.941 1.475 6.096zm-15.196.188c-.025.484-.02.969.013 1.452.074.503.19.999.349 1.482 1.494 4.919 6.328 7.775 10.98 6.419 4.113-1.199 6.324-4.19 6.862-8.251.486-3.671-.696-6.904-3.81-9.203-2.069-1.528-4.4-2.181-6.91-1.61-4.696 1.067-7.5 4.777-7.484 9.711z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M266.296 11.473v5.654l-.145.027c-.366-.832-.691-1.686-1.108-2.492-.943-1.823-2.504-2.673-4.522-2.613-1.773.053-3.232 1.565-2.997 3.227.146.74.548 1.406 1.137 1.878 1.249.971 2.644 1.754 3.98 2.613.883.568 1.783 1.112 2.647 1.708 1.735 1.197 2.735 2.814 2.246 4.956-.507 2.215-2.039 3.528-4.228 4.097-1.522.396-2.988.13-4.433-.348-1.13-.374-2.253-1.132-3.34.323v-5.507l.122-.01c.435.884.805 1.808 1.315 2.646 1.567 2.573 5.295 3.02 7.28.914 1.063-1.128 1.143-2.605-.001-3.641a24.416 24.416 0 0 0-3.484-2.568c-1.611-1.006-3.38-1.768-4.543-3.382-1.09-1.516-1.363-3.142-.526-4.814.905-1.81 2.44-2.867 4.482-2.923.988-.001 1.97.15 2.913.448 1.724.505 1.708.56 3.205-.193z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M217.475 11.46c-.394.177-.705.307-1.006.453-.974.471-1.502 1.162-1.475 2.335.062 2.7-.018 5.403.035 8.104.014 1.3.136 2.598.366 3.878.436 2.283 2.287 3.407 4.747 3.328 1.162-.038 2.358-.519 3.444-1.008 1.41-.635 2.112-1.96 2.15-3.427.099-3.854.003-7.712.084-11.567.02-.97-.415-1.297-1.177-1.534a1.836 1.836 0 0 1-.823-.52h5.64a3.7 3.7 0 0 1-.59.277c-1.416.418-1.92 1.418-1.908 2.82.021 2.758.025 5.516-.01 8.274.003.99-.083 1.978-.256 2.953-.585 3.002-3 4.412-5.603 4.777-1.835.258-3.672.129-5.374-.796-1.859-1.01-3.01-2.502-3.082-4.645-.108-3.205-.118-6.414-.186-9.62a9.943 9.943 0 0 0-.19-2.273c-.113-.432-.548-.825-.923-1.132a7.067 7.067 0 0 0-1.244-.676z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M183.445 29.922v.838h-12.532v-.873c.333 0 .682.004 1.03 0 .862-.011 1.684-.132 2.346-.769.447-.473.697-1.1.698-1.751.062-.82.082-1.644.083-2.467.006-7.067.008-14.135.005-21.203v-.47c-.274 0-.51-.003-.748.001-1.38.025-2.761.053-4.142.076-2.816.046-4.041 2.27-4.259 4.03-.014.177-.046.351-.094.521-.167.506-.456.628-.943.509l.337-6.992h24.117l.325 7.003c-.75-.033-.74-.036-.917-.674a24.955 24.955 0 0 0-.485-1.585c-.573-1.641-1.811-2.506-3.466-2.72-1.186-.154-2.395-.128-3.594-.166-.607-.02-1.215-.003-1.85-.003-.01.13-.024.24-.024.348.001 7.614.006 15.227.014 22.84.024.554.09 1.104.196 1.648.19 1.224 1.138 1.617 1.972 1.734.622.087 1.257.085 1.931.125z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M276.657 0l.167 2.214a.678.678 0 0 1-.136-.003c-.02-.004-.042-.035-.05-.058-.101-.297-.241-.58-.417-.84a2.101 2.101 0 0 0-1.325-.875 2.55 2.55 0 0 0-1.942.3c-.443.287-.772.72-.93 1.223a3.505 3.505 0 0 0-.21 1.041c-.009.189-.03.378-.026.566-.002.48.094.955.283 1.396.277.618.815 1.08 1.468 1.259.348.103.71.145 1.073.125.495-.01.974-.176 1.371-.472.25-.198.477-.421.68-.666.055-.065.11-.13.17-.198l.167.113c-.126.165-.243.333-.374.488-.324.4-.74.715-1.212.917-.266.11-.546.178-.832.203-.281.03-.564.04-.847.03a3.298 3.298 0 0 1-1.982-.74 3.146 3.146 0 0 1-1.086-1.804 3.415 3.415 0 0 1-.066-.993c.05-.722.329-1.41.797-1.96A3.47 3.47 0 0 1 273.066.19a3.785 3.785 0 0 1 1.332-.157c.393.023.78.111 1.143.263.145.06.289.122.438.168a.312.312 0 0 0 .362-.15c.038-.064.07-.132.108-.196a.214.214 0 0 1 .208-.117z"/>
            </g>
            <g>
              <path fill="#fff"
                    d="M274.888 4.756v.083h-1.24v-.087h.101a.318.318 0 0 0 .232-.076.253.253 0 0 0 .07-.173c.006-.081.007-.163.007-.244l.001-2.098v-.046h-.074l-.41.007a.428.428 0 0 0-.421.399.273.273 0 0 1-.01.051.067.067 0 0 1-.093.05l.033-.691h2.388l.032.693c-.075-.003-.074-.004-.091-.067a2.418 2.418 0 0 0-.048-.157.391.391 0 0 0-.343-.269c-.118-.015-.237-.012-.356-.016-.06-.002-.12 0-.183 0 0 .012-.003.023-.003.034l.002 2.26c.002.055.009.109.02.163a.204.204 0 0 0 .195.171c.06.009.124.009.19.013z"/>
            </g>
          </g>
        </g>
      </svg>
      <button @click="activeMobile = !activeMobile" type="button" class="mobileBtn">
        <span></span>
      </button>
    </div>
    <div class="flex btns-actions">
        <span class="current flex justify-center items-center"><svg class="mr-2" xmlns="http://www.w3.org/2000/svg"
                                                                    width="33" height="32" viewBox="0 0 33 32"><g><g><g><path
            fill="#00102f"
            d="M16.327 20.15c-4.158-.003-7.759 1.468-10.841 4.236-3.68-4.508-4.204-12.232.794-17.623 5.143-5.547 13.7-5.842 19.198-.866 5.64 5.106 5.787 13.292 1.685 18.498-3.078-2.772-6.679-4.244-10.836-4.246zM16.319 32c8.777-.004 15.901-7.138 15.906-15.926C32.231 7.278 25.081.133 16.3.16 7.5.185.4 7.294.41 16.074.42 24.867 7.548 32.004 16.319 32z"/></g><g><path
            fill="#00102f"
            d="M16.32 18.651c-3.47.002-6.291-2.807-6.299-6.274-.007-3.476 2.828-6.308 6.31-6.303 3.465.005 6.284 2.83 6.282 6.296-.001 3.466-2.82 6.279-6.292 6.281z"/></g></g></g></svg>
        Admin
      </span>
      <button @click="logout" class="logout  flex justify-center items-center" type="button">
        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="31" height="32" viewBox="0 0 31 32">
          <g>
            <g>
              <g>
                <path fill="#fff"
                      d="M0 15.008c.08-.586.133-1.177.243-1.757.968-5.134 3.756-8.986 8.36-11.414C16.56-2.362 26.395.897 30.358 8.96c.496 1.009.047 2.07-.985 2.327-.713.177-1.423-.16-1.782-.878-.553-1.104-1.216-2.135-2.058-3.04-2.75-2.956-6.158-4.39-10.182-4.208-5.857.265-10.778 4.516-11.965 10.235-1.391 6.701 2.758 13.355 9.399 15.074 5.804 1.502 11.915-1.247 14.652-6.591.057-.111.112-.224.171-.333.426-.792 1.33-1.093 2.104-.702.77.39 1.078 1.307.69 2.11a15.524 15.524 0 0 1-2.499 3.719c-2.628 2.887-5.875 4.618-9.74 5.18-.39.056-.781.098-1.172.146h-1.999c-.08-.018-.159-.046-.24-.052-1.673-.117-3.3-.47-4.832-1.145-5.29-2.33-8.55-6.317-9.673-12.01-.116-.588-.167-1.19-.248-1.786z"/>
              </g>
              <g>
                <path fill="#fff"
                      d="M19.12 17.577H16.42c-2.051 0-4.102.006-6.153-.004-1.072-.005-1.798-.97-1.515-1.985.194-.693.81-1.133 1.608-1.134 2.78-.002 5.56 0 8.34 0h.404c-.112-.123-.182-.205-.258-.282-.647-.648-1.296-1.295-1.943-1.944-.686-.689-.718-1.638-.08-2.28.634-.639 1.598-.616 2.283.065a661.718 661.718 0 0 1 3.776 3.777c1.306 1.32 1.303 3.137-.002 4.45a754.506 754.506 0 0 1-3.821 3.819c-.505.5-1.172.616-1.781.337a1.548 1.548 0 0 1-.424-2.533c.672-.667 1.34-1.338 2.01-2.009.07-.071.137-.147.258-.277z"/>
              </g>
            </g>
          </g>
        </svg>
        Uitloggen
      </button>
    </div>
  </header>
  <div class="flex bg-blue-100 main-container">
    <aside ref="aside" :class="{'active':activeMobile}"  class="aside flex flex-col  h-full">
      <router-link active-class="active-link" @click="activeMobile = false"  to="/">Dashboard</router-link>
      <router-link active-class="active-link" @click="activeMobile = false" to="/products">Producten</router-link>
      <router-link active-class="active-link" @click="activeMobile = false" to="/events">Events</router-link>
      <router-link active-class="active-link" @click="activeMobile = false" to="/exportEvents">Export maken</router-link>
    </aside>
    <slot/>
  </div>
</template>

<script setup>
import {ref}                 from 'vue';
import {useStore}                 from 'vuex';
import {useRouter}                 from 'vue-router'

const store = useStore();
const router = useRouter();
const activeMobile = ref(false);

async function logout() {
  await store.dispatch('user/logout');
  await router.push({name:'Login'});
}
</script>

<style scoped lang="scss">
header {
  background-color: #00102f;
  padding: 48px 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px 24px;
  }
}
.logo {
  @media (max-width: 768px) {
    margin-bottom: 25px;
    & svg {
      width: 200px;
    }
  }
}
.btns-actions {
  justify-content: flex-end;
}
.mobileBtn {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  width: 30px;
  height: 24px;
  position: relative;
  & span {
    width: 100%;
    height: 3px;
    background-color: white;
    display: block;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    display: block;
  }
}
.current {
  width: 114px;
  height: 48px;
  background-color: white;
  border-radius: 5px;
  margin-right: 8px;
  color: #00102f;
  font-size: 18px;
  font-weight: 700;
}
.logout {
  width: 132px;
  border-radius: 5px;
  height: 48px;
  background-color: #650505;
  color: white;
  font-size: 18px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 0;
    margin-right: 0;
    width: 50px;
    padding-left: 3px;
  }
}
.aside {
  width: 304px;
  background-color: #00102f;
  z-index: 30;
  @media (max-width: 1340px) {
    width: 217px;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 143px;
    left: 0;
    width: 100%;
    transform: translateX(100%);
    transition: 0.5s;

    &.active {
      transform: translateX(0);
    }
  }


  & a {
    @media (max-width: 768px) {
      text-align: center;
    }

    padding: 14px 24px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    transition: 0.3s;
    border-bottom: 1px solid white;
    text-align: left;

    &.active-link {
      background-color: rgb(28, 61, 110);
    }

    &:hover {
      background-color: rgb(28, 61, 110);
    }
  }
}

.main-container {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 80px);
}
</style>
