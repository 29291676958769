<template>
  <div class="overflow-auto all-products w-full p-10 product">
     <template v-if="eventItem">
      <EventForm @formSubmit="sendData" :eventItem="eventItem" :loading="loading"></EventForm>
    </template>
  </div>
</template>

<script setup>
import EventForm   from "@/components/EventForm.vue";
import axios       from 'axios';
import {ref}       from 'vue'
import {Config}    from "@/config";
import {useRouter} from 'vue-router'
import Until       from "@/until";
import {format}    from "date-fns";
import {createToaster} from "@meforma/vue-toaster";

const Toaster = createToaster({ position:'bottom-right', duration:3000,useDefaultCss: true});

const {createUUID} = Until;
const eventItem = ref();
const Router = useRouter();
const loading = ref(false);
eventItem.value = {
  post_title  : '',
  post_content: '',
  post_status : 'publish',
  event_cat   : [],
  post_meta   : {
    limit_level        : 0,
    limit_level_value  : '',
    event_terms        : '',
    event_date_time    : '',
    event_end_date_time: '',
  },
}

const API_URL_POST = Config.APP_WP_URL + '/wp-json/api/headless/events/create';

async function sendData(data)
{
  loading.value = true;
  data.post_meta.event_date_time = data.post_meta.event_date_time ? format(new Date(data.post_meta.event_date_time), 'yyyy-MM-dd HH:mm') : ''
  data.post_meta.event_end_date_time = data.post_meta.event_end_date_time ? format(new Date(data.post_meta.event_end_date_time), 'yyyy-MM-dd HH:mm') : ''

  const formData = new FormData();
  formData.append('data', JSON.stringify(data));
  const response = await axios.post(API_URL_POST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  const {value: id} = JSON.parse(response.data);

  if (response.status === 200) {
    loading.value = false;
    Toaster.success('Opgeslagen', {});
    setTimeout(() => {
      Router.push({name: 'Event', params: {id}})
    },1000)
  } else {
    loading.value = false;
    Toaster.error('Opslaan mislukt', {});
  }
}

</script>

<style lang="scss">

</style>
