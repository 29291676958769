import { createStore } from 'vuex'
import {products}      from "@/store/products.module";
import {events}      from "@/store/events.module";
import {attributes}      from "@/store/attributes.module";
import {productCategories}      from "@/store/productCategories.module";
import {eventCategories}      from "@/store/eventCategories.module";
import {productTags}      from "@/store/productTags.module";
import {user}      from "@/store/user.module";


export default createStore({
  modules: {
    products,
    events,
    attributes,
    productCategories,
    productTags,
    eventCategories,
    user
  }
})
