import AttributesService from '@/services/attributes.service'

export const attributes = ({
    namespaced: true,
    state: {
        events : [],
    },
    getters: {
    },
    mutations: {
        SET_ATTRS (state, data) {
            state.attributes = data
        },
    },
    actions: {
        getAttributes({commit}) {
            return AttributesService.getAllAttributes().then((response) => {
                commit('SET_ATTRS', response.data)
                return Promise.resolve(response.data)
            });
        },
    },
    modules: {
    }
})
