<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script setup>
import AppLayoutDefault from './AppLayoutDefault.vue';
import AppLayoutAuth from './AppLayoutAuth.vue';
import {markRaw, watch, ref} from 'vue';
import {useRoute} from "vue-router";

const layout = ref();
const route = useRoute();

watch(() => route.meta?.layout,
    async (metaLayout) => {
        if (metaLayout === 'AppLayoutAuth') {
            layout.value = markRaw(AppLayoutAuth)
        } else {
            layout.value = markRaw(AppLayoutDefault)
        }
    },
    {immediate: true}
)
</script>

<style scoped>

</style>
